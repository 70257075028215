import { Box, Flex, Heading } from "@chakra-ui/react";
import NewsCarousel from "../NewsCarousel";
import SponsorCarousel from "../SponsorCarousel";

const LastNews = () => {
  return (
    <Flex flexDir={"column"}>
      <Heading
        p={[2, 6]}
        fontFamily={"Afacad"}
        variant={"outline"}
        colorScheme="green"
        textDecor={"underline"}
        textDecorationColor={"green"}
      >
        Últimas Notícias
      </Heading>
      <Flex
        alignItems={"flex-start"}
        m={"auto"}
        p={5}
        mt={2}
        w={["100%", "90%", "85%", "95%"]}
        flexDir={["column", "row"]}
        gap={5}
      >
        <Box
          w={["100%", "60%"]}
          borderRadius={"1rem"}
          zIndex={"80"}
          boxShadow={"lg"}
        >
          <NewsCarousel />
        </Box>
        <Box w={["100%", "40%"]} alignSelf={"center"}>
          <SponsorCarousel type="Principal" />
        </Box>
      </Flex>
      <hr />
    </Flex>
  );
};
export default LastNews;
