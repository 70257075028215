import { Box, Flex, Heading } from "@chakra-ui/react";
import SponsorCarousel from "../SponsorCarousel";
import VideosCarousel from "../VideosCarousel";

const LastVideos = () => {
  return (
    <Flex flexDir={"column"}>
      <Heading
        fontFamily={"Afacad"}
        variant={"outline"}
        colorScheme="green"
        textDecor={"underline"}
        textDecorationColor={"green"}
      >
        Vídeos em Destaque
      </Heading>
      <Flex
        alignItems={"flex-start"}
        m={"auto"}
        p={5}
        mt={2}
        w={["100%", "90%", "85%", "95%"]}
        flexDir={["column", "row"]}
        gap={5}
      >
        <Box
          w={["100%", "60%"]}
          borderRadius={"1rem"}
          zIndex={"80"}
          boxShadow={"lg"}
        >
          <VideosCarousel />
        </Box>
        <Box w={["100%", "40%"]} alignSelf={"center"}>
          <SponsorCarousel type="Principal" />
        </Box>
      </Flex>
      <hr />
    </Flex>
  );
};
export default LastVideos;
