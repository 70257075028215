import { Flex, Heading, Text } from "@chakra-ui/react";
import Layout from "../components/Layout";

const Privacy = () => {
  return (
    <Layout>
      <Flex
        m={"auto"}
        w={"80%"}
        flexDir={"column"}
        alignItems={"center"}
        gap={5}
      >
        <Heading>Política de Privacidade e Segurança</Heading>
        <Text fontStyle={"italic"} fontSize={"0.8rem"}>
          Baseada no modelo desenvolvido por Dr. Diego Castro
          (https://diegocastro.adv.br)
        </Text>
        <Text>
          Conforme as normas previstas na Lei 13.709/18, que versa sobre a
          proteção de dados e as demais legislações pertinentes, apresentamos a
          Política de Privacidade e Segurança de nosso site – www.acaol.org. ​
          Este documento é vinculado e integra as Condições e Termos de Uso
          deste site, sendo passível de alteração a qualquer momento para
          adequação em regras estabelecidas pelas legislações que estabelecem o
          modo de tratamento dos dados. ​ O acesso, visita e permanência neste
          site é de livre escolha e acontece conforme a vontade/desejo do
          usuário, sendo sua visita e prosseguimento no site, escolha deste
          usuário. ​ Ficando ciente por meio deste documento que a partir do
          momento em que mantém o acesso e a permanência em qualquer uma das
          nossas páginas está automaticamente demonstrando estar de acordo, de
          forma explicita com todas as regras e termos trazidos na Política de
          Privacidade e Segurança descrita abaixo. ​ Sendo seu direito a
          qualquer momento entrar em contato conosco para maiores informações do
          que aqui foi descrito, bem como esclarecimento de dúvidas que possam
          vir a existir. ​ O site www.acaol.org foi criado em 15 de julho de
          2.022 e é de inteira propriedade ACAOL – Associação Cultural e
          Assistencial Obreiros do Leste, inscrita sob o CNPJ nº
          02.542.928/0001-23. ​ Esta Política de Privacidade foi modificada em
          25 de Abril de 2022, criada pelo Advogado Diego Castro, e utilizada,
          com a devida autorização, neste site. Informações Gerais: ​ Nosso site
          se compromete a cumprir e respeitar os princípios previstos no art. 6º
          da Lei Geral de Proteção de dados, estando eles abaixo elencados e
          esclarecidos conforme ao tratamento de dados realizado por este site:
          ​ I – Princípio da Finalidade: Este site respeita o que prevê o
          princípio da finalidade trazido na legislação supracitada, realizando
          o tratamento com finalidade legítima e específica, informando por meio
          desta Política de Privacidade e Segurança de forma explicita, aos
          titulares dos dados que serão tratados o motivo do tratamento. Nenhum
          tratamento será realizado de forma incompatível com as finalidades que
          serão informadas neste documento. ​ II – Princípio da Necessidade:
          Coletamos e tratamos apenas os dados necessários para a realização das
          finalidades explicitas do nosso site, com o compromisso de coletar e
          tratar o mínimo de informações do usuário do nosso site. Utilizamos
          apenas as informações/dados pertinentes, proporcionais com a a
          atividade desempenhada por nós, sem exceder nenhum limite na relação
          existente entre o usuário e o nosso site, sendo todo e qualquer dado
          coletado e tratado conforme as finalidades estabelecidas em nossas
          páginas. ​ III – Princípio da Adequação: Todo tratamento realizado em
          nossas páginas é compatível com as finalidades informadas para o
          usuário, respectivo titular dos dados, não sendo realizado nenhum tipo
          de ação ou mecanismo que seja não condizente e desrespeite o contexto
          de tratamento necessário. ​ IV – Não discriminação: Os dados que
          coletamos, armazenamos e que realizamos qualquer tipo de tratamento
          não são utilizados para nenhum fim discriminatório, vexatório, ilícito
          ou abusivo. ​ V – Princípio da Qualidade dos dados: Garantimos aos
          titulares dos dados clareza e relevância no momento da coleta e do
          tratamento dos dados, além da possibilidade de atualização, conforme
          se fizer necessário para que seja cumprida a finalidade do tratamento,
          conforme a atividade que for realizada pelo usuário em nosso site. ​
          VI – Principio do Livre Acesso: Esclarecemos por meio desta Política
          de Privacidade e Segurança que garantimos a todos os titulares de
          dados que realizamos o tratamento, a consulta de maneira gratuita e
          fácil, a qualquer momento em que desejarem, por meio de um canal
          específico, sobre o tratamento realizado com os seus dados, assim como
          qualquer informação referente aos dados coletados, podendo inclusive
          acontecer atualização dos dados pessoais do titular, sem que a
          necessidade de qualquer requisição por parte do nosso site. ​ VII –
          Princípio da Prevenção: Adotamos e zelamos pela prevenção de todo e
          qualquer ato que possa gerar dano aos dados coletados dos usuários,
          com medidas implantadas em busca de prevenir qualquer situação
          incomoda, ilegal, ou que gere qualquer espécie de prejuízo/dano aos
          dados pessoais que realizamos tratamos. ​ VIII – Princípio da
          Transparência: Trabalhamos com clareza, precisão, exatidão e
          veracidade em todas as informações trazidas sobre o tratamento de
          dados, sendo o seu acesso fácil e disponível ao usuário nesta Política
          de Privacidade, bem como nos Termos de Uso no nosso site. As
          informações respectivas aos agentes de tratamento seguem o mesmo
          critério de transparência acima elencados. Todas as informações
          trazidas sobre o tratamento e os agentes de tratamento respeitam os
          segredos comerciais e industriais. ​ IX – Princípio da Segurança:
          Informamos que utilizamos todas as medidas cabíveis e possíveis na
          seara técnica e administrativa, visando a maior proteção dos dados
          pessoais, desde o momento em que temos acesso a eles. As técnicas
          utilizadas buscam proteger os dados pessoais de todo e qualquer acesso
          não autorizado, bem como de situações que possam causar danos ao
          titular dos dados, ainda que acidentais ou ilícitas. As técnicas que
          utilizamos buscam trazer segurança e proteção ao titular dos dados que
          realizamos o tratamento, visto que são tomadas em busca de prevenir e
          proteger as informações coletadas de qualquer situação ilícita de
          perda, destruição, compartilhamento, comunicação ou difusão não
          autorizada. ​ X – Princípio da responsabilização e prestação de
          contas: O agente irá demonstrar e comprovar que cumpre e respeita
          todas as normas cabíveis a Proteção de Dados Pessoais, adotando
          medidas adequadas para isso de maneira eficaz. O nosso site realiza
          todas as etapas referentes ao tratamento de dados pessoais dos
          usuários que o acessam, sem desrespeitar qualquer norma ou princípio
          presente na Lei 13.709/2018, tendo de forma clara e objetiva o
          fundamento da finalidade para o tratamento de cada informação pessoal
          coletada. ​ Sobre o Nosso Site: Nosso site possuí textos, vídeos,
          imagens, áudios, e-books, bem como os demais conteúdos que você poderá
          visualizar ao acessar nossas abas, todos sem nenhum teor
          discriminatório, ilícito ou difamatório. Traçamos planos e metas a
          serem seguidos para apresentar um conteúdo de valor, com informações
          relevantes referente ao tema aqui abordado. ​ Assumimos o compromisso
          por meio deste documento de proporcionar o melhor resultado no momento
          da navegação do nosso usuário, nos comprometendo a agir e trabalhar
          com total segurança e respeitando a privacidade dos dados pessoais
          tratados. ​ Tenha atenção a todas as informações disponibilizadas
          nesta página, pois é por meio dela que trazemos de forma clara e
          direta como acontecem todas as etapas de tratamento das informações
          pessoais coletadas, inclusive deixando claro o compartilhamento e
          divulgação (em alguns casos) que realizamos com parceiros. ​ A
          qualquer momento está Política de Privacidade e Segurança poderá ser
          modificada, conforme atualizações normativas ou alterações em nossa
          Política Interna, razão pela qual convidamos ao (a) nosso (a) usuário
          (a) a consultar este documento periodicamente para que tenham ciência
          imediata de qualquer alteração que venha a acontecer. ​ Informações
          sobre o controlador e Responsável pelo Tratamento de dados Conforme
          previsão do art. 5º da Lei Geral de Proteção de dados trazemos aqui
          informações sobre o controlador definido pela legislação “in verbis”:
          Art. 5º, VI – controlador: pessoa natural ou jurídica, de direito
          público ou privado, a quem competem as decisões referentes ao
          tratamento de dados pessoais; www.acaol.org tem como CONTROLADOR e
          responsável por definir e tomar qualquer decisão referente ao
          tratamento de dados realizado por nós e por nossa equipe os Srs. Prof.
          José Renato dos Santos, presidente da ACAOL,
          joserenatoconsultoria@gmail.com , 11 99726-6196 e Oswaldo Nunes Cabral
          Neto, oswaldocabral@ito.cabral.nom.br, 11 99604-9171. ​ O CONTROLADOR
          é um profissional/empresa de nossa inteira confiança, que está ciente
          de todas as normas trazidas na legislação que realiza a proteção de
          dados, além de ter total ciência e conhecimento das normas
          estabelecidas na nossa Política Interna. ​ Nosso conteúdo também pode
          ser encontrado no Google, Bing, Yahoo e ou outros buscadores. Já o
          tratamento de dados coletados em nosso site é realizado pelo Sr.
          Oswaldo Nunes Cabral Neto, oswaldocabral@ito.cabral.nom.br, 11
          99604-9171. ​ Informações sobre o Conteúdo do site e Classificação
          Indicativa: O conteúdo trazido é direcionado para pessoas que tenham
          interesse, curiosidade ou busquem informações a respeito do conteúdo
          do nosso site. Todo conteúdo abordado em nossas páginas é produzido
          por nossa equipe ou por parceiros, respeitando as legislações
          nacionais, bem como, os valores éticos e humanos, além dos princípios
          morais, zelando pela transparência e veracidade em todas as
          informações trazidas. ​ O nosso site, bem como o seu conteúdo e os
          parceiros são indicados para pessoas com idade a partir de 18 anos.
          Caso você não tenha 18 anos de idade completos tenha ciência de que é
          necessário permissão do seu responsável para você ter acesso aos
          conteúdos trazidos em nossas páginas, sendo de total responsabilidade
          DO RESPONSÁVEL pelo menor de idade o acesso ao nosso material, bem
          como os conteúdos trazidos por nossos parceiros. ​ Sobre o tratamento
          de dados: Nosso site trabalha com determinadas operações e
          funcionalidades das quais se fazem necessárias o tratamento de dados
          pessoais, desde a etapa da sua coleta até a sua eliminação, ou
          armazenamento, como será discorrido abaixo: ​ Da coleta de dados:
          Realizamos a coleta de dados dos usuários/visitantes do nosso site não
          apenas para realização dos serviços que prestamos, como também por
          segurança, já que qualquer ato irregular ou ilícito praticado por ele
          poderá ser passível de consequências especificas para o responsável. A
          utilização de determinadas funções do nosso site depende expressamente
          da coleta de dados dos usuários como: nome, telefone de contato,
          e-mail, bem como os demais dados cabíveis para a finalidade que se
          apresenta no site. A Coleta de Dados pode acontecer por todas as áreas
          do nosso site, uma vez que ela acontece por meio do preenchimento de
          formulários, FAQ, envio de dúvidas, Chat ou cadastro. Cientificamos
          ainda, que ao preencher formulário/FAQ/Cadastro ou qualquer tipo de
          área referente ao retorno de contato ou dúvidas em nosso site, assim
          como o ACEITE dos COOKIES que utilizamos, O USUÁRIO demonstra A
          CONCORDÂNCIA de forma objetiva, clara e expressa da coleta e
          tratamento de seus dados que será realizada pelo site/equipe do site/
          parceiros do site respeitando todas as normas de proteção aos dados
          pessoais. ​ É indispensável, ainda, informarmos que trabalhamos com
          ferramenta de análise de dados (analytics ou similar) para monitorar
          os resultados de acessos, comentários, entre outras informações sobre
          o desempenho do nosso site. ​ Outros dados Nosso site também realiza a
          coleta e o tratamento de dados referentes ao dispositivo e vinculados
          ao registro de acesso. ​ Dados do dispositivo: Coletamos dados
          vinculados ao dispositivo que acessa ao nosso site, principalmente por
          motivo de segurança – dados como: modelo do hardware, sistema
          operacional, identificadores de dispositivo (localização e etc.). ​
          Registros de Acesso: Coletamos e tratamos ações vinculadas ao uso de
          dados, desde a sua inclusão, até a exclusão de dados, IP do usuário,
          data (dia/mês/ano), hora do acesso realizado. ​ Dados de navegação:
          Realizamos a coleta e o tratamento de dados relativos à navegação,
          assim podemos desempenhar uma melhor experiencia para o usuário –
          navegador onde o usuário realizou o acesso e páginas acessadas. ​
          Forma de coleta de dados A coleta de dados dos nossos usuários
          acontece respeitando todos os princípios e normas trazidos na Lei
          Geral de Proteção de Dados, com a condição expressa de consentimento
          livre e espontâneo do usuário para isso, visto que não realizamos
          nenhum tratamento sem permissão do usuário ou seu responsável. ​ Não
          utilizamos nenhum meio coercivo, autoritário, opressor, repressivo ou
          abusivo em busca de receber o consentimento do usuário para o
          tratamento de seus dados. A tabela abaixo informa como acontece a
          coleta dos dados: Dado Como acontece a coleta Dados pessoais do
          usuário Coletados por meio de formulários, FAQS, Fale Conosco, Chat
          etc. Dados do dispositivo Coletados/Transmitidos/Transferidos pelo
          acesso ao serviço que acontece por meio da internet Registro de acesso
          Coletado/Transmitidos/Transferidos pelo acesso ao serviço que acontece
          por meio da internet Dados de navegação Coletados por meio de cookies
          durante a navegação no site. A coleta de dados pessoais pode acontecer
          em diversos locais, páginas e abas do nosso site, por meio de
          preenchimento de formulários, FAQS, chat e cadastros necessários para
          o envio de materiais, realização de compras ou envio de informações. O
          registro de acesso, os dados do dispositivo são coletados para a
          segurança do usuário bem como do proprietário do site e toda a sua
          equipe por meio do acesso ao serviço que disponibilizamos, que
          acontece no momento do acesso a nossa página. ​ Como buscamos conhecer
          melhor o nosso público trabalhamos com alguns mecanismos e plataformas
          que realizam a coleta de dados de nossos usuários automaticamente
          diretamente pelo sistema, sendo respeitadas todas as regras da LGPD,
          assim como a privacidade do usuário, proporcionando todos os meios
          técnicos e administrativos de segurança. Dados de navegação são
          coletados pelos nossos cookies a partir do momento em que se inicia a
          navegação no nosso site. Finalidade do tratamento dos dados pessoais
          Todos os dados que realizamos tratamentos são utilizados respeitando
          os princípios da Lei Geral de Proteção de Dados, principalmente o da
          finalidade, adequação e necessidade, sendo mantidos apenas em nosso
          sistema até o momento em que a sua finalidade continuar a existir. ​
          Dado/Informações Finalidade Dados do usuário Identificar o usuário
          para a finalidade de cadastro/envio de materiais/respostas de
          dúvidas/envio de novidades etc. Dados do dispositivo Trazer um melhor
          acesso ao usuário, pois através dos dados coletados podemos adequar
          melhor o site ao dispositivo/navegador do usuário Registro de Acesso
          Por meio destes dados proporcionamos maior segurança aos proprietários
          do site e aos usuários, pois assim podemos identificar qualquer ato
          que aconteça dentro do nosso site. Informações de Navegação Através
          dessas informações conhecemos melhor o nosso usuário, podendo
          passar-lhe conteúdo que ele tem interesse, bem como indicar serviço
          dos nossos parceiros que ele demonstre preferência, que serão
          encontradas por meio das páginas que ele mais acessou. Os dados dos
          usuários são coletados e tratados unicamente com a finalidade para
          qual eles são fornecidos, sendo explicito em cada parte do nosso site
          para o que servirá o dado fornecido seja por meio de cadastro, FAQ,
          envio de formulários, envios de novidades etc. Os dados do dispositivo
          são tratados para que possamos proporcionar a melhor experiência no
          seu acesso ao site, uma vez que é por meio dele que podemos adequar a
          visualização do site ao dispositivo/navegador utilizado. Os registros
          de acesso são itens imprescindíveis no momento da realização de coleta
          e tratamento realizado pelo nosso site, pois é por meio dele que
          podemos proporcionar maior segurança para os usuários e para a nossa
          equipe, uma vez que por meio desses dados pode identificar informações
          como endereço de IP, data, hora, atividade realizada etc., em qualquer
          área do nosso site, podendo objetivar qualquer ato ilícito que possa
          vir a acontecer. As informações de navegação são a melhor forma de
          trazer conteúdo nosso e dos nossos parceiros para você, pois é através
          das informações de navegação que dividimos para cada usuário notícias,
          publicidades entre outros materiais de sua preferência. Essa
          preferência é objetivada pelos dados encontrados em páginas mais
          acessadas pelo usuário. ​ Tratamento realizado nos dados O tratamento
          de dados realizados no nosso site respeita todas as normas previstas
          na legislação de proteção de dados, tendo base legal para ser
          realizado, acontecendo apenas com consentimento do usuário. Todos os
          dados tratados são disponíveis para alteração e atualização a qualquer
          momento, inclusive durante todo o seu armazenamento. Todos os dados
          coletados serão armazenados durante 5 (cinco anos) podendo ser
          alterado conforme a necessidade do serviço prestado, ou por motivo de
          qualquer disposição que possa vir a existir. Veja a descrição do modo
          de tratamento realizado na tabela abaixo: Dado/Informação Tratamento
          realizado Dados Pessoais Coleta, armazenamento, compartilhamento e
          comunicação. Dados do Dispositivo Coleta, armazenamento,
          processamento, compartilhamento, comunicação de informações de
          dispositivos que visitam nosso site. Dados de Navegação Coleta,
          armazenamento, processamento, compartilhamento, comunicação de
          informações vinculadas as preferências e comportamento do
          usuário/visitante que acessa ao nosso site. ​ Dados não coletados
          Respeitando os princípios contidos no início desta Política de
          Privacidade e Segurança não realizamos a coleta de nenhum dado que não
          seja necessário para os serviços que prestamos, ou que tenha
          finalidade diversa das informadas. Não coletamos dados sensíveis,
          exceto em casos em que houver determinação legal para isso, sendo
          informado o usuário de forma expressa da necessidade de tratamento
          caso venha a existir, bem como o tratamento que será realizado com
          ele. ​ Compartilhamento/divulgação/publicação dos dados coletados
          Respeitamos todos os limites previstos na Lei Geral de Proteção de
          dados referentes ao compartilhamento/divulgação ou publicação de dados
          coletados por nós, agindo de boa-fé e moralmente sem nenhuma intenção
          de causar dano ao nosso usuário. Os dados serão compartilhados apenas
          com parceiros, se e quando necessário, sendo ciente o usuário de que
          concede o compartilhamento dos seus dados ao aceitar preencher
          formulários em nosso site em troca de materiais gratuitos ou promoções
          aos membros do site. Os dados coletados e compartilhados conforme
          informação mencionada acima podem gerar retorno monetário ao
          proprietário e a equipe do site, visto que ao preencher formulários em
          troca de materiais torna-se direito deste site compartilhar as
          informações fornecidas em busca de gerar renda para manter este
          projeto. Em casos previstos em leis, boletim, ou caso recebamos algum
          tipo de determinação de autoridade pública ou por meio de alguma
          determinação regulatória é realizado o compartilhamento de dados, a
          publicação ou divulgação. ​ O titular tem direito de permitir o
          compartilhamento e o acesso de seus dados a um terceiro, desde que
          informe de maneira expressa a permissão que deu ao titular. ​ Em
          situações em que somos informados que há investigação criminal ao ser
          requisitada cooperação do nosso site, deixamos cientes os nossos
          usuários que cooperamos do modo como nos for requisitado. ​ Sobre os
          nossos parceiros Todos os parceiros que trabalham conosco respeitam as
          normas previstas na Lei Geral de Proteção de Dados, bem como as demais
          legislações que tratam sobre esse assunto. Informamos que o nosso site
          contém links e hiperlinks de nossos parceiros, ao clicar em algum
          desses links ou hiperlinks você é direcionado a um site externo, que
          não possui nenhum vínculo com o nosso site, tendo Política de
          Privacidade e Segurança, Política Interna e Termos de Uso diferente
          dos nossos. Não temos nenhum poder ou informações para discorrer do
          modo como acontece o tratamento de dados pelos nossos parceiros, por
          isso busque conhecer a Política de Privacidade, Termos de Uso, bem
          como os demais documentos que versam sobre o tratamento de dados
          deles. Sobre a nossa Equipe Nossa equipe tem ciência de todos os
          mecanismos técnicos e administrativos que devem ser utilizados no
          tratamento de dados, pois informamos em nossa Política Interna,
          conhecida por todos os nossos colaboradores o modo como deve acontecer
          todas as etapas do tratamento de dados, respeitando a privacidade e
          todas as normas trazidas na Lei 13.709/18. ​ OBS: Toda equipe que
          trabalha conosco tem conhecimento de todas as práticas e técnicas que
          devem ser utilizadas no tratamento dos dados que coletamos, pois todos
          tiveram e continuam a ter acesso a nossa Política Interna e Política
          de Governança, bem como possuem treinamentos específicos para isso,
          sendo que qualquer ato (realizado por alguém que participe da nossa
          equipe) considerado ilícito ou danoso ao titular de dados gerara
          responsabilização direta e será motivo cabível para demissão com justa
          causa. ​ Segurança no Tratamento de Dados O nosso site tem o
          compromisso de realizar toda e qualquer medida técnica, administrativa
          e institucional referente a proteção de dados pessoais. As técnicas de
          segurança utilizadas protegem os dados do usuário de acessos por
          terceiros não autorizados, bem como de qualquer ocasião que possa
          trazer algum tipo de modificação, destruição, difusão,
          compartilhamento, divulgação, comunicação dos dados sem autorização e
          desrespeitando a Lei Geral de Proteção de Dados. ​ Todas as medidas de
          proteção e segurança são tomadas especificadamente para o dado
          coletado, respeitando o contexto e a finalidade que essa informação
          possui. O nosso site aplica criptografia e/ou firewall proporcionando
          assim a transmissão de maneira segura, privativa e confidencial. As
          transmissões que acontecem entre o transmissor e o usuário acontecem
          de maneira totalmente cifrada, em busca de proteger os dados
          coletados. Nós nos comprometemos a avisar ao titular dos dados de
          qualquer violação ou acesso suspeito que possa causar algum risco de
          dano aos direitos e liberdades pessoais do titular. Disponibilizamos o
          canal de comunicação no final deste texto para que o nosso usuário
          possa informar qualquer situação que identifique falha,
          vulnerabilidade na segurança ou defeito na realização do tratamento
          dos dados coletados. ​ Cookies e Newsletter Os cookies são arquivos
          que possuem tamanhos pequenos e são enviados pelo nosso site ao seu
          computador, para que assim possamos armazenar informações de navegação
          e os dados do usuário necessários para o serviço que desempenhamos. Os
          cookies são o meio pelo qual realizamos o registro de dados e
          preferenciais dos nossos usuários de modo rápido para que possamos
          proporcionar uma experiência adequada ao ser realizado o acesso a
          nossa página, trazendo inclusive serviços relacionados as pesquisas
          que você faz em nosso site. Informamos, ainda, que nem todos os
          cookies trazidos neste site realizam a coleta e o armazenamento de
          dados pessoais, visto que alguns dos cookies trazidos são utilizados
          para o desempenho de algum dos serviços que prestamos. A “newsletter”
          é o meio pelo qual notificamos e informamos os nossos usuários de
          qualquer atualização que realizamos. Os dados informados para a
          “newsletter” são utilizados apenas com essa finalidade, sem qualquer
          destinação contrária ou que desrespeite a legislação de Proteção de
          Dados. Você não é obrigado a aceitar os “cookies” ou a “newsletter”, é
          de sua livre escolha optar ou não pelo registro de “cookies”; caso não
          deseje que ele aconteça, utilize a opção em seu navegador/aparelho de
          desativação do registro de cookies. ​ Informamos que a desativação dos
          cookies pode lhe trazer uma experiência incompleta ou com falhas na
          sua visita ao nosso site, além de não trazer o desempenho correto das
          ferramentas e funções que disponibilizamos em nossas páginas. A
          desativação dos cookies pode ocasionar ainda prejuízos na prestação do
          nosso serviço fim, bem como, em alguns casos não poderemos lhe
          proporcionar uma experiência mais adequada as suas preferências pois
          não teremos acesso a elas. ​ Tratamento de dados para outras
          finalidades Respeitando o que prevê a Lei Geral de Proteção de Dados
          informamos aos nossos usuários que podemos realizar o tratamento de
          seus dados para finalidades diversas posteriormente, sem que isso
          cause qualquer dano ou desrespeito ao titular dos dados, informando
          abaixo a finalidade de uso posterior das informações coletadas. ​ As
          informações que coletamos sobre o titular, dispositivo, navegação,
          localização etc., podem continuar a ser utilizadas para que
          desenvolvamos a prestação de serviços mais aprimorados e de uma melhor
          experiência para o usuário em nossa página. Caso o titular dos dados
          requeira a exclusão de seus dados pode entrar em contato conosco pelo
          e-mail informado nesta Política de Privacidade. ​ Após o requerimento
          do titular na exclusão de seus dados acontecer, anonimizaremos os
          dados, sendo que esses continuarão em nossos arquivos de modo
          anonimizado, podendo ser utilizados futuramente com a finalidade de
          pesquisa, geração de estatísticas, divulgação de informações
          especificas, e ainda, em publicações. ​ Consentimento do usuário O
          usuário, ao acessar o nosso site, demonstra estar de acordo com todas
          as informações contidas em nossa Política de Privacidade e Termos de
          Uso, bem como concede, de livre e espontânea vontade, que seus dados
          sejam tratados pela nossa equipe respeitando os princípios da
          finalidade, necessidade e adequação. É livre o consentimento do
          usuário, uma vez que não utilizamos de nenhum meio de coação,
          obrigatoriedade, indução, constrangimento, ameaça ou imposição para
          que o usuário conceda e permita que seja realizado o tratamento de
          seus dados. ​ Eliminação de dados Caso o usuário não permita o
          armazenamento de seus dados, pode entrar em contato conosco por meio
          dos canais de contato abaixo: E-mail: contato@acaol.org Telefone: 11
          94035-1090 e 11 99604-9171 Após o requerimento de eliminação de dados,
          temos o prazo de 30 (trinta) dias para que os dados sejam eliminados
          ou anonimizados. ​ Canais de Contato Disponibilizamos canais de
          contato para o nosso usuário em busca de termos um relacionamento
          claro e cordial, nos disponibilizando para esclarecer qualquer dúvida
          sobre o nosso site, bem como sobre o modo como realizamos o tratamento
          de seus dados. Buscamos por meio desse profissional ter uma
          comunicação melhor e mais direta com os nossos usuários. ​
          Disponibilizamos abaixo Canais de Atendimento para você poder entrar
          em contato com o nosso responsável pelo controle de dados, Sr. Oswaldo
          Nunes Cabral Neto E-mail: oswaldocabral@ito.cabral.nom.br ● Telefone:
          11 996504-9171 Esta Política de Privacidade e Segurança passa a ter
          validade a partir de 25 de abril de 2022, com validade indeterminada.
          ​ FONTE: https://diegocastro.adv.br/modelo-politica-privacidade/
        </Text>
      </Flex>
    </Layout>
  );
};
export default Privacy;
