import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SponsorItem = ({ sponsor, onDelete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const split = process.env.REACT_APP_S3_SPONSORIMAGES.split("/");
  const navigate = useNavigate();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{sponsor.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir={"column"} gap={1.5}>
              <Flex gap={2}>
                <Text fontWeight={700}>Tipo: </Text>
                <Text>{sponsor.type}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Término Patrocínio: </Text>
                <Text>
                  {sponsor.endDate
                    ? format(new Date(sponsor.endDate), "dd/MM/yyyy")
                    : "Indefinido"}
                </Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Patrocínio ativo? </Text>
                <Text>{sponsor.isActive ? "Ativo" : "Inativo"}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Link Alvo:</Text>
                <Text>
                  <a
                    href={sponsor.targetUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {sponsor.targetUrl}
                  </a>
                </Text>
              </Flex>
              <Flex justifyContent={"center"} w={"100%"}>
                <Image
                  src={`https://${split[0]}.s3.amazonaws.com/${split[1]}/${sponsor?.images[0]}`}
                  objectFit={"cover"}
                />
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Voltar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Tr>
        <Td>{sponsor.name}</Td>

        <Td>{sponsor.type}</Td>
        <Td>
          {sponsor.endDate
            ? format(new Date(sponsor.endDate), "dd/MM/yyyy")
            : "Indefinido"}
        </Td>
        <Td>
          {
            <a href={sponsor.targetUrl} rel="noreferrer" target="_blank">
              Link
            </a>
          }
        </Td>
        <Td>{sponsor.isActive ? "Ativo" : "Inativo"}</Td>
        <Td>
          <Flex gap={5}>
            <Tooltip label={"Detalhar patrocinador"}>
              <IconButton
                colorScheme="blue"
                aria-label="Detalhes"
                size="sm"
                onClick={onOpen}
                icon={<SearchIcon />}
              />
            </Tooltip>
            <Tooltip label={"Excluir patrocinador"}>
              <IconButton
                colorScheme="red"
                aria-label="Excluir"
                size="sm"
                isLoading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  onDelete(sponsor.sponsorId);
                }}
                icon={<DeleteIcon />}
              />
            </Tooltip>
            <Tooltip label={"Editar patrocinador"}>
              <IconButton
                colorScheme="yellow"
                aria-label="Editar"
                size="sm"
                isLoading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  navigate(`/createSponsor/${sponsor.sponsorId}`);
                }}
                icon={<EditIcon />}
              />
            </Tooltip>
          </Flex>
        </Td>
      </Tr>
    </>
  );
};

export default SponsorItem;
