import {
  Badge,
  Box,
  Button,
  Flex,
  FormLabel,
  IconButton,
  Image,
  Input,
  Select,
  Skeleton,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 } from "uuid";
import { myBucket } from "../../utils/bucket";
import { MdAddAPhoto } from "react-icons/md";
import client from "../../utils/client";
import { useNavigate, useParams } from "react-router-dom";

const VideoForm = () => {
  const { videoId } = useParams();
  const [video, setVideo] = useState();
  const { register, handleSubmit } = useForm();
  const toast = useToast();
  const [newImage, setNewImage] = useState(video?.images[0] || undefined);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const fileInput = useRef(null);

  useEffect(() => {
    if (videoId) {
      setIsLoading(true);
      const request = async () => {
        const response = await client.get(`videos/${videoId}`);
        response.data && setVideo(response.data);
      };
      try {
        request().then(() => setIsLoading(false));
      } catch (error) {
        console.log(error);
      }
    }
  }, [videoId]);

  const uploadFile = async (file, imageId) => {
    const response = await fetch(file);
    const blob = await response.blob();
    const fileObj = new File([blob], file.name, { type: blob.type });
    const params = {
      ACL: "public-read",
      Body: fileObj,
      Bucket: process.env.REACT_APP_S3_VIDEOSIMAGES,
      Key: imageId,
    };

    myBucket.putObject(params, (err, data) => {
      if (err) console.log(err);
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const logo = `videoImage-${v4()}.jpeg`;
      uploadFile(newImage, logo);

      const tags = data.tagsUnited.split(", ");

      const { name, url, eventName, authorName, gradeRestriction } = data;

      await client.post("/videos", {
        image: logo,
        tags,
        name,
        url,
        eventName,
        authorName,
        gradeRestriction,
      });

      toast({
        title: "Vídeo cadastrado com sucesso",
        description: "O vídeo foi cadastrado!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/allVideos");
    } catch (error) {
      toast({
        title: "Algum erro aconteceu",
        description: "Contate o Administrador do Sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const onEdit = async (data) => {
    setIsLoading(true);
    try {
      let logo;
      if (newImage !== video.images[0]) {
        logo = `videoImage-${v4()}.jpeg`;
        uploadFile(newImage, logo);
      } else {
        logo = video.images[0];
      }
      await client.put(`/videos/${video.videoId}`, {
        images: [logo],
        ...data,
      });
      toast({
        title: "Vídeo alterado com sucesso",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/allVideos");
    } catch (error) {
      console.log(error);
      toast({
        title: "Algum erro aconteceu",
        description: "Contate o Administrador do Sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const handleImage = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setNewImage(URL.createObjectURL(file));
    }
  };

  return (
    <Skeleton isLoaded={!isLoading}>
      <form onSubmit={video ? handleSubmit(onEdit) : handleSubmit(onSubmit)}>
        <Flex flexDir="column" gap="5" my={5}>
          <Box
            border={"1px solid #ccc"}
            borderRadius={"8px"}
            p={[2, 5]}
            bgColor={"white"}
          >
            <Badge fontSize={"1rem"} colorScheme="whatsapp" mb={2}>
              Informações do Vídeo
            </Badge>
            <Flex flexDir={"column"} gap={3}>
              <Stack direction={["column", "row"]}>
                <FormLabel w={"100%"} m={0}>
                  Título do Vídeo
                  <Input
                    required
                    type="text"
                    placeholder="Nome do vídeo"
                    {...register("name")}
                    defaultValue={video && video.name}
                  />
                </FormLabel>
              </Stack>
              <Stack direction={["column", "row"]}>
                <FormLabel w={"100%"} m={0}>
                  Link de destino
                  <Input
                    required
                    type="text"
                    placeholder="http://..."
                    {...register("url")}
                    defaultValue={video && video.url}
                  />
                </FormLabel>
              </Stack>
              <Stack direction={["column", "row"]}>
                <FormLabel w={"100%"} m={0}>
                  Nome do Evento
                  <Input
                    required
                    type="text"
                    placeholder=""
                    {...register("eventName")}
                    defaultValue={video && video.eventName}
                  />
                </FormLabel>
                <FormLabel w={"100%"} m={0}>
                  Restrição por Grau
                  <Select {...register("gradeRestriction")}>
                    <option value={"1"}>Sem restrições</option>
                    <option value={"2"}>Para Companheiros</option>
                    <option value={"3"}>Para Mestres</option>
                  </Select>
                </FormLabel>
              </Stack>
              <Stack direction={["column", "row"]}>
                <FormLabel w={"100%"} m={0}>
                  Tags (Separe por vírgula)
                  <Input
                    required
                    type="text"
                    placeholder="história, filosofia..."
                    {...register("tagsUnited")}
                    defaultValue={video && video?.tags.map((tag) => `${tag}, `)}
                  />
                </FormLabel>

                <FormLabel w={"100%"} m={0}>
                  Autor do Vídeo
                  <Input
                    type="text"
                    {...register("authorName")}
                    defaultValue={video && video?.authorName}
                  />
                </FormLabel>
              </Stack>
            </Flex>
          </Box>

          <Box
            p={[2, 5]}
            bgColor={"white"}
            border={"1px solid #ccc"}
            borderRadius={"8px"}
          >
            <Badge fontSize={"1rem"} colorScheme="whatsapp" mb={2}>
              Imagem do vídeo para o Card
            </Badge>
            <Flex
              flexDir={["column", "row"]}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={5}
            >
              <Flex flexDir={["column", "row"]} w={["100%"]}>
                <div>
                  <Input
                    type="file"
                    accept="image/*"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleImage}
                    ref={fileInput}
                  />
                  <label htmlFor="fileInput">
                    <Flex alignItems={"center"} gap={10}>
                      <FormLabel m={0}>Adicione a imagem do Vídeo</FormLabel>
                      <IconButton
                        colorScheme="whatsapp"
                        borderRadius={"100%"}
                        w={["70px", "85px"]}
                        h={["70px", "85px"]}
                        aria-label="Upload File"
                        icon={<MdAddAPhoto fontSize={"45px"} />}
                        onClick={() => fileInput.current.click()}
                      />
                    </Flex>
                  </label>
                </div>
              </Flex>
              <Flex justifyContent={"center"} w={"100%"}>
                {newImage && <Image objectFit={"cover"} src={newImage} />}
              </Flex>
            </Flex>
          </Box>

          <Flex justifyContent={"center"}>
            <Button
              isLoading={isLoading}
              colorScheme="whatsapp"
              w={["100%", "40%"]}
              type="submit"
            >
              Cadastrar Vídeo
            </Button>
          </Flex>
        </Flex>
      </form>
    </Skeleton>
  );
};

export default VideoForm;
