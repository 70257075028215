import { Flex, Heading, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Contacts = () => {
  return (
    <Flex flexDir={"column"}>
      <Heading
        fontFamily={"Afacad"}
        variant={"outline"}
        colorScheme="green"
        textDecor={"underline"}
        textDecorationColor={"green"}
        p={[2, 6]}
      >
        Contatos
      </Heading>
      <Flex flexDir={["column", "row"]} p={[2, 6]} gap={[3, 10]} mt={3}>
        <Text fontFamily={"Afacad"}>contato@acaol.org</Text>
        <Link to={"/privacy"}>Declação de privacidade e dados</Link>
      </Flex>
    </Flex>
  );
};
export default Contacts;
