import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import client from "../utils/client";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Flex,
  Box,
  Skeleton,
  Heading,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormLabel,
  Input,
  Select,
  IconButton,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { useForm } from "react-hook-form";
import { v4 } from "uuid";

const EventContent = () => {
  const { register, handleSubmit, reset } = useForm();
  const { eventId } = useParams();
  const [event, setEvent] = useState();
  const [reload, setReload] = useState(false);

  const auth = useAuth();
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  useEffect(() => {
    setIsLoadingPage(true);
    const request = async () => {
      const response = await client.get(`/events/${eventId}`);
      response.data && setEvent(response.data);
    };
    request().then(() => setIsLoadingPage(false));
  }, [eventId, reload]);

  const onSubmit = async (data) => {
    setIsLoadingPage(true);
    await client
      .patch(`/events/${event.eventId}`, {
        videos: [...event?.videos, { ...data, comments: [] }],
      })
      .then(() => {
        setReload(!reload);
        reset();
        onAddClose();
      });
  };

  const onDelete = async (data) => {
    const filtered = event.videos.filter((el) => el.videoId !== data);
    setIsLoadingPage(true);
    await client
      .patch(`/events/${event.eventId}`, {
        videos: filtered,
      })
      .then(() => {
        setReload(!reload);
      });
  };

  return (
    <>
      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar Conteúdo</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <Flex w={"100%"} gap={3} flexDir={"column"}>
                <Flex flexDir={"column"} gap={3}>
                  <Stack direction={["column", "row"]}>
                    <FormLabel w={"100%"} m={0}>
                      Nome do Conteúdo
                      <Input
                        required
                        type="text"
                        placeholder="Nome do conteúdo"
                        {...register("name")}
                      />
                    </FormLabel>
                  </Stack>
                  <Stack direction={["column", "row"]}>
                    <FormLabel w={"100%"} m={0}>
                      Autor
                      <Input
                        required
                        type="text"
                        placeholder="Nome do autor"
                        {...register("author")}
                      />
                    </FormLabel>
                  </Stack>
                  <Stack direction={["column", "row"]}>
                    <FormLabel w={"100%"} m={0}>
                      URL
                      <Input
                        required
                        type="text"
                        placeholder="https://..."
                        {...register("url")}
                      />
                    </FormLabel>
                  </Stack>

                  <Stack direction={["column", "row"]}>
                    <FormLabel w={"100%"} m={0}>
                      Hora de liberação
                      <Input
                        required
                        type="time"
                        placeholder="https://..."
                        {...register("liberationTime")}
                      />
                    </FormLabel>
                  </Stack>
                  <Stack direction={["column", "row"]}>
                    <FormLabel w={"100%"} m={0}>
                      Restrição
                      <Select {...register("gradeRestriction")}>
                        <option value={"1"}>Sem restrições</option>
                        <option value={"2"}>Para Companheiros</option>
                        <option value={"3"}>Para Mestres</option>
                      </Select>
                    </FormLabel>
                  </Stack>
                  <Input type="hidden" value={v4()} {...register("videoId")} />
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onAddClose}>
                Voltar
              </Button>
              <Button colorScheme="green" mr={3} type="submit">
                Cadastrar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Layout>
        {auth?.user.type === process.env.REACT_APP_ADMINISTRATOR ? (
          <Flex justifyContent={"center"}>
            <Box
              w={["90%", "80%", "80%", "70%"]}
              padding="6"
              boxShadow="lg"
              bg="white"
              mt={[8]}
            >
              <Skeleton isLoaded={!isLoadingPage}>
                <Heading
                  fontFamily={"Afacad"}
                  variant={"outline"}
                  colorScheme="green"
                  textDecor={"underline"}
                  textDecorationColor={"green"}
                >
                  Gerenciar conteúdo - {event && event.name}
                </Heading>

                <Flex alignItems={"center"} flexDir={"column"}>
                  <Flex
                    w={"100%"}
                    gap={5}
                    wrap={"wrap"}
                    justifyContent={"center"}
                    mt={5}
                  >
                    <Button colorScheme="blue" onClick={onAddOpen}>
                      Novo Conteúdo
                    </Button>

                    <TableContainer w={"100%"}>
                      <Table size={"sm"} variant="striped" colorScheme="green">
                        <Thead>
                          <Tr>
                            <Th>Vídeo</Th>
                            <Th>Hora disponível</Th>
                            <Th>Restrição</Th>
                            <Th>Ações</Th>
                          </Tr>
                        </Thead>

                        <Tbody>
                          {!!event?.videos[0] &&
                            event.videos.map((cont) => (
                              <Tr key={cont.videoId}>
                                <Td>{cont?.name}</Td>
                                <Td>{cont?.liberationTime}</Td>
                                <Td>
                                  {cont?.gradeRestriction === "1"
                                    ? "Sem Restrição"
                                    : cont?.gradeRestriction === "2"
                                    ? "Só Companheiros"
                                    : "Só Mestres"}
                                </Td>
                                <Td>
                                  <IconButton
                                    colorScheme="red"
                                    aria-label="Detalhes"
                                    size="sm"
                                    isLoading={isLoadingPage}
                                    onClick={() => {
                                      onDelete(cont.videoId);
                                    }}
                                    icon={<DeleteIcon />}
                                  />
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Flex>
                </Flex>
              </Skeleton>
            </Box>
          </Flex>
        ) : (
          <p>NÃO AUTORIZADO</p>
        )}
      </Layout>
    </>
  );
};
export default EventContent;
