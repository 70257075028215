import { useEffect, useState } from "react";
import client from "../../utils/client";
import { Button, Flex, Skeleton, Text } from "@chakra-ui/react";
import VideoCard from "../VideoCard";
import { useAuth } from "../../context/AuthContext";

const OtherVideos = () => {
  const [videos, setVideos] = useState();
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const auth = useAuth();

  useEffect(() => {
    setIsLoadingPage(true);
    const request = async () => {
      const response = await client.get(`/videos?page=${currentPage}`);
      setVideos(response.data.videoItems);
      setCurrentPage(response.data.currentPage);
      setTotalPages(
        Array.from(
          { length: response.data.totalPages },
          (_, index) => index + 1
        )
      );
    };
    request().then(() => setIsLoadingPage(false));
  }, [currentPage]);

  return (
    <Skeleton isLoaded={!isLoadingPage}>
      <Flex gap={[3, 6]} wrap={"wrap"} justifyContent={"center"}>
        {videos &&
          videos.map((el) => {
            if (
              Number(auth.user.symbolicGrade) >= Number(el.gradeRestriction)
            ) {
              return <VideoCard video={el} />;
            } else {
              return <></>;
            }
          })}
      </Flex>

      <Flex gap={[2]} mt={5} alignItems={"center"}>
        {totalPages[0] &&
          totalPages.map((page) => {
            if (page === currentPage) {
              return <Text mx={2}>{page}</Text>;
            } else {
              return (
                <Button
                  key={page}
                  colorScheme="linkedin"
                  size="sm"
                  variant={page === currentPage ? "outline" : "solid"}
                  disabled={page === currentPage ? "true" : "false"}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </Button>
              );
            }
          })}
      </Flex>
    </Skeleton>
  );
};
export default OtherVideos;
