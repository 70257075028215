import { Box, Flex, Heading, Skeleton } from "@chakra-ui/react";
import Layout from "../components/Layout";
import EventForm from "../components/EventForm";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import client from "../utils/client";

const CreateEvent = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (eventId) {
      setIsLoading(true);
      const request = async () => {
        const response = await client.get(`events/${eventId}`);
        console.log(response.data);
        response.data && setEvent(response.data);
      };
      try {
        request().then(() => setIsLoading(false));
      } catch (error) {
        console.log(error);
      }
    }
  }, [eventId]);

  return (
    <Layout>
      <Flex justifyContent={"center"}>
        <Box
          w={["90%", "80%", "80%", "70%"]}
          padding="6"
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Heading
            fontFamily={"Afacad"}
            variant={"outline"}
            colorScheme="green"
            textDecor={"underline"}
            textDecorationColor={"green"}
          >
            {!eventId ? "Criar Evento" : "Editar Evento"}
          </Heading>
          <Flex alignItems={"center"} flexDir={"column"}>
            <Box w={"100%"}>
              <Skeleton isLoaded={!isLoading}>
                {!eventId ? (
                  <EventForm />
                ) : (
                  event && <EventForm event={event} />
                )}
              </Skeleton>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Layout>
  );
};
export default CreateEvent;
