import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import "./style.css";

const Layout = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleExit = () => {
    auth.logout(() => navigate("/"));
  };

  const handleImageError = (e) => {
    e.target.src = process.env.PUBLIC_URL + "/placePhoto2.png";
  };

  return (
    <Box
      p={[5, 12]}
      h={["400px", "800px"]}
      bgGradient={
        "linear-gradient(0deg, rgba(189,231,181,0) 0%, rgba(189,231,181,1) 77%)"
      }
    >
      <Flex
        bgColor={"rgba(255, 255, 255, 0.6)"}
        h={["3rem", "5rem"]}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderRadius={"0.5rem"}
        mt={[4, 0]}
        w={"100%"}
      >
        <Flex w={["", "", "", "100%"]} alignItems={"center"}>
          <Image
            h={["5.5rem", "5.5rem", "5.5rem", "8rem"]}
            minW={["5.5rem", "5.5rem", "5.5rem", "8rem"]}
            ml={["-0.2rem", "-0.8rem"]}
            onClick={() => navigate("/")}
            cursor={"pointer"}
            src={process.env.PUBLIC_URL + "/logoBig.png"}
          />

          <Image
            minW={["", "", "", "", "22rem", "35rem"]}
            display={["none", "none", "none", "none", "block"]}
            src={process.env.PUBLIC_URL + "/name.png"}
          />
        </Flex>
        <Flex w={"100%"} justifyContent={"center"}>
          <Text
            display={["block", "block", "block", "none"]}
            fontSize={"0.80rem"}
            textAlign={"center"}
            fontWeight={700}
          >
            Associação Cultural Assistencial Obreiros do Leste
          </Text>
        </Flex>
        <Flex
          pr={5}
          gap={5}
          display={["none", "none", "none", "flex"]}
          alignItems="center"
          justifyContent="flex-end"
          w={"50%"}
        >
          <Link to={"/"}>
            <Button fontSize={"1rem"} colorScheme="green" variant={"link"}>
              Home
            </Button>
          </Link>
          <Link to={"/news"}>
            <Button fontSize={"1rem"} colorScheme="green" variant={"link"}>
              Notícias
            </Button>
          </Link>
          <Link to={"/acaolflix"}>
            <Button fontSize={"1rem"} colorScheme="green" variant={"link"}>
              Acaol-Flix
            </Button>
          </Link>
          <Link to={"/eventos"}>
            <Button fontSize={"1rem"} colorScheme="green" variant={"link"}>
              Eventos
            </Button>
          </Link>
          {!auth.user?.id && (
            <Link to={"/register"}>
              <Button fontSize={"1rem"} colorScheme="green" variant={"link"}>
                Registrar
              </Button>
            </Link>
          )}

          {auth.user?.type === process.env.REACT_APP_ADMINISTRATOR && (
            <Menu>
              <MenuButton
                colorScheme="whatsapp"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="teste"
                minW={"15rem"}
              >
                Funções de Administrador
              </MenuButton>
              <MenuList>
                <Link to={"/allNews"}>
                  <MenuItem>Gerenciar Notícias</MenuItem>
                </Link>
                <Link to={"/allUsers"}>
                  <MenuItem>Gerenciar Usuários</MenuItem>
                </Link>
                <Link to={"/allSponsors"}>
                  <MenuItem>Gerenciar Patrocinadores</MenuItem>
                </Link>
                <Link to={"/allEvents"}>
                  <MenuItem>Gerenciar Eventos</MenuItem>
                </Link>
                <Link to={"/allVideos"}>
                  <MenuItem>Gerenciar Acaol-Flix</MenuItem>
                </Link>
              </MenuList>
            </Menu>
          )}

          {auth.user?.type === process.env.REACT_APP_NEWS && (
            <Menu>
              <MenuButton
                colorScheme="whatsapp"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="teste"
              >
                Criação de conteúdos
              </MenuButton>
              <MenuList>
                <Link to={"/allNews"}>
                  <MenuItem>Gerenciar Notícias</MenuItem>
                </Link>
              </MenuList>
            </Menu>
          )}

          {auth.user?.type === process.env.REACT_APP_CULTURAL && (
            <Menu>
              <MenuButton
                colorScheme="whatsapp"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="teste"
              >
                Comissão Cultural
              </MenuButton>
              <MenuList>
                <Link to={"/allEvents"}>
                  <MenuItem>Gerenciar Eventos</MenuItem>
                </Link>
              </MenuList>
            </Menu>
          )}

          {auth.user?.id ? (
            <Button onClick={handleExit} fontSize={"1rem"} colorScheme="red">
              Sair
            </Button>
          ) : (
            <Link to={"/login"}>
              <Button fontSize={"1rem"} colorScheme="green">
                Login
              </Button>
            </Link>
          )}
          {auth?.userPhoto && (
            <Link to={"/profile"}>
              <Box w={"55px"} h={"55px"}>
                <Image
                  src={auth.userPhoto}
                  w="100%"
                  h="100%"
                  objectFit={"cover"}
                  borderRadius={"50%"}
                  onError={handleImageError}
                />
              </Box>
            </Link>
          )}
        </Flex>
        <Flex display={["flex", "flex", "flex", "none"]} pr={2}>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
              colorScheme="green"
              backgroundColor={"white"}
            />
            <MenuList zIndex={100}>
              <Link to={"/"}>
                <MenuItem>Home</MenuItem>
              </Link>
              <Link to={"/news"}>
                <MenuItem>Notícias</MenuItem>
              </Link>
              <Link to={"/eventos"}>
                <MenuItem>Eventos</MenuItem>
              </Link>
              {!auth?.user && (
                <>
                  <Link to={"/login"}>
                    <MenuItem>Login</MenuItem>
                  </Link>
                  <Link to={"/register"}>
                    <MenuItem>Registrar</MenuItem>
                  </Link>
                </>
              )}

              <Link to={"/acaolflix"}>
                <MenuItem>Acaol-Flix</MenuItem>
              </Link>

              {auth?.user && (
                <>
                  <Link to={"/profile"}>
                    <MenuItem>Meu Perfil</MenuItem>
                  </Link>
                  <MenuItem onClick={handleExit}>Sair</MenuItem>
                </>
              )}

              {auth.user?.type === process.env.REACT_APP_ADMINISTRATOR && (
                <>
                  <Link to={"/allNews"}>
                    <MenuItem bgColor={"#22c35e60"}>
                      Gerenciar Notícias
                    </MenuItem>
                  </Link>
                  <Link to={"/allUsers"}>
                    <MenuItem bgColor={"#22c35e60"}>
                      Gerenciar Usuários
                    </MenuItem>
                  </Link>
                  <Link to={"/allSponsors"}>
                    <MenuItem bgColor={"#22c35e60"}>
                      Gerenciar Patrocinadores
                    </MenuItem>
                  </Link>
                  <Link to={"/allEvents"}>
                    <MenuItem bgColor={"#22c35e60"}>Gerenciar Eventos</MenuItem>
                  </Link>
                  <Link to={"/allVideos"}>
                    <MenuItem bgColor={"#22c35e60"}>
                      Gerenciar Acaol-Flix
                    </MenuItem>
                  </Link>
                </>
              )}
              {auth.user?.type === process.env.REACT_APP_NEWS && (
                <>
                  <Link to={"/allNews"}>
                    <MenuItem bgColor={"#22c35e60"}>
                      Gerenciar Notícias
                    </MenuItem>
                  </Link>
                </>
              )}
              {auth.user?.type === process.env.REACT_APP_CULTURAL && (
                <>
                  <Link to={"/allEvents"}>
                    <MenuItem bgColor={"#22c35e60"}>Gerenciar Eventos</MenuItem>
                  </Link>
                </>
              )}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <Box pb={"5rem"}>{children}</Box>

      <Flex
        pos="fixed"
        bottom="0"
        left="0"
        width="100%"
        height="3rem"
        bg="#0B2027"
        zIndex="2"
        alignItems={"center"}
        justifyContent={"center"}
        gap={5}
      >
        <Text
          fontSize={"1.15rem"}
          fontWeight={500}
          color={"white"}
          fontFamily={"Afacad"}
        >
          © AppiDucks 2024
        </Text>
        <Link to={"https://wa.me/+5593992003313"} target="_blank">
          <Image
            borderRadius={8}
            src={`${process.env.PUBLIC_URL}/laranja.png`}
            h={"2rem"}
          />
        </Link>
      </Flex>
    </Box>
  );
};

export default Layout;
