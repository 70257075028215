import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import Layout from "../components/Layout";
import { useForm } from "react-hook-form";
import client from "../utils/client";

const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { register: registerA, handleSubmit: handleSubmitA } = useForm();
  const { register: registerB, handleSubmit: handleSubmitB } = useForm();

  const handleSubmitLogin = async (data) => {
    setIsLoading(true);

    try {
      await auth.signin(data, () => navigate("/", { replace: true }));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitPassword = async (data) => {
    setIsLoading(true);
    try {
      await client.post(`/users/recover`, data).then(() => setIsLoading(false));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <Flex mt={5} justifyContent={"center"}>
        <FormControl
          border={"1px solid #ccc"}
          borderRadius={"8px"}
          p={[2, 6]}
          bgColor={"white"}
          isRequired
          w={["100%", "80%", "80%", "40%"]}
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Heading
            fontFamily={"Afacad"}
            variant={"outline"}
            colorScheme="green"
            textDecor={"underline"}
            textDecorationColor={"green"}
          >
            Login
          </Heading>
          <form id="a" onSubmit={handleSubmitA(handleSubmitLogin)}>
            <Flex mt={5} gap={5} flexDir={"column"} alignItems={"center"}>
              <Box w={"100%"}>
                <FormLabel htmlFor="email">E-mail</FormLabel>
                <Input
                  type="text"
                  id="email"
                  name="email"
                  {...registerA("email")}
                />
              </Box>
              <Box w={"100%"}>
                <FormLabel htmlFor="password">Senha </FormLabel>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  {...registerA("password")}
                />
              </Box>

              <Button
                w={"40%"}
                isLoading={isLoading}
                type="submit"
                colorScheme="green"
                variant="outline"
              >
                Logar
              </Button>
            </Flex>
          </form>
          <form id="b" onSubmit={handleSubmitB(handleSubmitPassword)}>
            <Flex
              w={"100%"}
              mt={5}
              gap={2}
              flexDir={"column"}
              alignItems={"center"}
            >
              <FormLabel alignSelf={"flex-start"} htmlFor="email">
                Esqueceu sua senha?
              </FormLabel>
              <Input
                id="emailRecover"
                name="emailRecover"
                type="email"
                {...registerB("email")}
                placeholder="Email cadastrado"
              />
              <Button
                w={"40%"}
                isLoading={isLoading}
                type="submit"
                colorScheme="green"
                variant="outline"
              >
                Enviar
              </Button>
            </Flex>
          </form>
        </FormControl>
      </Flex>
    </Layout>
  );
};

export default Login;
