import { Box, Flex } from "@chakra-ui/react";
import Layout from "../components/Layout";
import LastVideos from "../components/LastVideos";
import OtherVideos from "../components/OtherVideos";

const AcaolFlix = () => {
  return (
    <Layout>
      <Flex justifyContent={"center"}>
        <Box
          w={["100%", "90%", "90%", "90%"]}
          p={[2, 6]}
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Flex flexDir={"column"} gap={[5, 5]}>
            <LastVideos />
            <OtherVideos />
          </Flex>
        </Box>
      </Flex>
    </Layout>
  );
};
export default AcaolFlix;
