import { Flex, Heading, Image, Text } from "@chakra-ui/react";

const HelpCard = ({ image, title, text }) => {
  return (
    <Flex
      alignItems={"center"}
      m={"auto"}
      p={5}
      mt={2}
      w={["100%", "20%"]}
      flexDir={"column"}
      gap={5}
      justifyContent={"space-between"}
    >
      <Image src={`${image}`} borderRadius={"100%"} />
      <Flex flexDir={"column"} gap={3} mt="6" alignItems={"center"}>
        <Heading size="md">{title}</Heading>
        <Text fontFamily={"Afacad"} fontSize={"1.1rem"}>
          {text}
        </Text>
      </Flex>
    </Flex>
  );
};

export default HelpCard;
