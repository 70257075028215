import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import client from "../utils/client";
import {
  Box,
  Flex,
  Heading,
  Input,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import UserCard from "../components/UserCard";
import { useAuth } from "../context/AuthContext";

const AllUsers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const auth = useAuth();

  useEffect(() => {
    console.log("carregou");
    const request = async () => {
      const response = await client.get("/users", {
        headers: { Authorization: auth.user.token },
      });
      if (response?.data) {
        const ordered = response.data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setAllUsers(ordered);
      }
    };
    try {
      request().then(() => setIsLoading(false));
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [auth.user.token]);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setFilteredUsers(
      allUsers.filter((el) =>
        el.name.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  };

  return (
    <Layout>
      <Flex justifyContent={"center"}>
        <Box
          w={["90%", "80%", "80%", "70%"]}
          padding="6"
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Skeleton isLoaded={!isLoading}>
            <Heading
              fontFamily={"Afacad"}
              variant={"outline"}
              colorScheme="green"
              textDecor={"underline"}
              textDecorationColor={"green"}
            >
              Gerenciar Usuários
            </Heading>
            <Flex alignItems={"center"} flexDir={"column"}>
              <Flex
                w={"100%"}
                gap={5}
                wrap={"wrap"}
                justifyContent={"center"}
                mt={5}
              >
                <Input
                  type="text"
                  placeholder="Pesquisar"
                  onChange={handleSearch}
                />
                <TableContainer w={"100%"}>
                  <Table size={"sm"} variant="striped" colorScheme="green">
                    <Thead>
                      <Tr>
                        <Th>Nome</Th>
                        <Th>Email</Th>
                        <Th>Celular</Th>
                        <Th>Tipo</Th>
                        <Th>Ações</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredUsers[0]
                        ? filteredUsers.map((user) => (
                            <UserCard key={user.userId} user={user} />
                          ))
                        : allUsers[0] &&
                          allUsers.map((user) => (
                            <UserCard key={user.userId} user={user} />
                          ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Flex>
            </Flex>
          </Skeleton>
        </Box>
      </Flex>
    </Layout>
  );
};

export default AllUsers;
