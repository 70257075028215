import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import client from "../utils/client";

const Recover = () => {
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const { register: registerA, handleSubmit: handleSubmitA } = useForm();
  const toast = useToast();

  const handleSubmitLogin = async (data) => {
    setIsLoading(true);
    try {
      await client.patch(`/users/changePassword`, data).then(() => {
        setIsLoading(false);
        toast({
          title: "Senha alterada",
          description: "Senha alterada com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Algum erro aconteceu.",
        description: "Contate o administrador do sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Layout>
      <Flex mt={5} justifyContent={"center"}>
        <FormControl
          border={"1px solid #ccc"}
          borderRadius={"8px"}
          p={[2, 5]}
          bgColor={"white"}
          isRequired
          w={["90%", "80%", "80%", "40%"]}
          padding="6"
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Heading
            fontFamily={"Afacad"}
            variant={"outline"}
            colorScheme="green"
            textDecor={"underline"}
            textDecorationColor={"green"}
          >
            Recuperar Senha
          </Heading>
          <form id="a" onSubmit={handleSubmitA(handleSubmitLogin)}>
            <Flex mt={5} gap={5} flexDir={"column"} alignItems={"center"}>
              <Box w={"100%"}>
                <FormLabel htmlFor="newPassword">Nova Senha</FormLabel>
                <Input type="password" {...registerA("newPassord")} />
              </Box>

              <Input type="hidden" value={token} {...registerA("token")} />

              <Button
                w={"40%"}
                isLoading={isLoading}
                type="submit"
                colorScheme="green"
                variant="outline"
              >
                Mudar Senha
              </Button>
            </Flex>
          </form>
        </FormControl>
      </Flex>
    </Layout>
  );
};
export default Recover;
