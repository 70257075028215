import { DeleteIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useState } from "react";

const NewsItem = ({ news, onDelete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{news.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex w={"90%"} gap={3} flexDir={"column"}>
              <Text>
                {news.authorName} -{" "}
                {format(new Date(news.createdAt), "dd/MM/yyyy")}
              </Text>
              <Text
                dangerouslySetInnerHTML={{
                  __html: news.text.replace(/(?:\r\n|\r|\n)/g, "<br>"),
                }}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Voltar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Tr>
        <Td>{news.title}</Td>
        <Td>{format(new Date(news.createdAt), "dd/MM/yyyy")}</Td>
        <Td>{news.authorName}</Td>
        <Td>
          <Flex gap={5}>
            <Tooltip label={"Detalhes"}>
              <IconButton
                colorScheme="blue"
                aria-label="Detalhes"
                size="sm"
                onClick={onOpen}
                icon={<SearchIcon />}
              />
            </Tooltip>
            <Tooltip label={"Excluir notícia"}>
              <IconButton
                colorScheme="red"
                aria-label="Excluir"
                size="sm"
                isLoading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  onDelete(news.newsId);
                }}
                icon={<DeleteIcon />}
              />
            </Tooltip>
          </Flex>
        </Td>
      </Tr>
    </>
  );
};
export default NewsItem;
