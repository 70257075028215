import { Box, Flex, Heading } from "@chakra-ui/react";
import Layout from "../components/Layout";
import LastEvents from "../components/LastEvents";
import OtherEvents from "../components/OtherEvents";
import { useAuth } from "../context/AuthContext";

const Events = () => {
  const auth = useAuth();
  return (
    <Layout>
      {auth?.user?.id ? (
        <Flex justifyContent={"center"}>
          <Box
            w={["100%", "90%", "90%", "90%"]}
            p={[2, 6]}
            boxShadow="lg"
            bg="white"
            mt={[8]}
          >
            <Flex flexDir={"column"} gap={[5, 5]}>
              <LastEvents />
              <OtherEvents />
            </Flex>
          </Box>
        </Flex>
      ) : (
        <Flex justifyContent={"center"}>
          <Box
            w={["100%", "90%", "90%", "90%"]}
            p={[2, 6]}
            boxShadow="lg"
            bg="white"
            mt={[8]}
          >
            <Heading>Eventos públicos</Heading>
            <OtherEvents publicEvents={true} />
          </Box>
        </Flex>
      )}
    </Layout>
  );
};
export default Events;
