import { Box, Flex, Heading } from "@chakra-ui/react";
import Layout from "../components/Layout";
import VideoForm from "../components/VideoForm";

const CreateVideo = () => {
  return (
    <Layout>
      <Flex justifyContent={"center"}>
        <Box
          w={["90%", "80%", "80%", "70%"]}
          padding="6"
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Heading
            fontFamily={"Afacad"}
            variant={"outline"}
            colorScheme="green"
            textDecor={"underline"}
            textDecorationColor={"green"}
          >
            Adicionar Vídeo
          </Heading>
          <Flex alignItems={"center"} flexDir={"column"}>
            <Box w={"100%"}>
              <VideoForm />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Layout>
  );
};

export default CreateVideo;
