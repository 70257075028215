//import { createBrowserRouter } from "react-router-dom";
import Register from "./Register";
import { RequireAuth } from "../context/AuthContext";
import Login from "./Login";
import Profile from "./Profile";
import AllUsers from "./AllUsers";
import AcaolFlix from "./AcaolFlix";
import Home from "./Home";
import AllNews from "./AllNews";
import CreateNews from "./CreateNews";
import News from "./News";
import AllSponsors from "./AllSponsors";
import CreateSponsor from "./CreateSponsor";
import AllVideos from "./AllVideos";
import CreateVideo from "./CreateVideo";
import WatchVideo from "./WatchVideo";
import AllEvents from "./AllEvents";
import CreateEvent from "./CreateEvent";
import Events from "./Events";
import EventDetails from "./EventDetails";
import Recover from "./Recover";
import Privacy from "./Privacy";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import EventInscriptions from "./EventInscriptions";
import EventDay from "./EventDay";
import EventContent from "./EventContent";
import EventVideo from "./EventVideo";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Home />,
//   },
//   {
//     path: "/index.html",
//     element: <Home />,
//   },
//   { path: "/privacy", element: <Privacy /> },
//   { path: "/#/privacy", element: <Privacy /> },
//   {
//     path: "/login",
//     element: <Login />,
//   },
//   {
//     path: "/register",
//     element: <Register />,
//   },
//   {
//     path: "/reset/:token",
//     element: <Recover />,
//   },
//   {
//     path: "/acaolflix",
//     element: (
//       <RequireAuth>
//         <AcaolFlix />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/eventos",
//     element: (
//       <RequireAuth>
//         <Events />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/eventos/:eventId",
//     element: (
//       <RequireAuth>
//         <EventDetails />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/acaolflix/:videoId",
//     element: (
//       <RequireAuth>
//         <WatchVideo />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/profile",
//     element: (
//       <RequireAuth>
//         <Profile />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/#/profile",
//     element: (
//       <RequireAuth>
//         <Profile />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/news",
//     element: <News />,
//   },
//   {
//     path: "/news/:newsId",
//     element: <News />,
//   },
//   {
//     path: "/allUsers",
//     element: (
//       <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
//         <AllUsers />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/allNews",
//     element: (
//       <RequireAuth
//         requiredRole={[
//           process.env.REACT_APP_ADMINISTRATOR,
//           process.env.REACT_APP_NEWS,
//         ]}
//       >
//         <AllNews />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/createNews",
//     element: (
//       <RequireAuth
//         requiredRole={[
//           process.env.REACT_APP_ADMINISTRATOR,
//           process.env.REACT_APP_NEWS,
//         ]}
//       >
//         <CreateNews />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/allSponsors",
//     element: (
//       <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
//         <AllSponsors />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/allEvents",
//     element: (
//       <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
//         <AllEvents />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/allVideos",
//     element: (
//       <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
//         <AllVideos />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/createSponsor",
//     element: (
//       <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
//         <CreateSponsor />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/createSponsor/:sponsorId",
//     element: (
//       <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
//         <CreateSponsor />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/createVideo",
//     element: (
//       <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
//         <CreateVideo />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/createVideo/:videoId",
//     element: (
//       <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
//         <CreateVideo />
//       </RequireAuth>
//     ),
//   },
//   {
//     path: "/createEvent",
//     element: (
//       <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
//         <CreateEvent />
//       </RequireAuth>
//     ),
//   },
// ]);

const router = (
  <Router>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/index.html" element={<Home />} />
      <Route path="/privacy" element={<Privacy />} />

      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/reset/:token" element={<Recover />} />
      <Route
        path="/acaolflix"
        element={
          <RequireAuth>
            <AcaolFlix />
          </RequireAuth>
        }
      />
      <Route path="/eventos" element={<Events />} />
      <Route path="/eventos/:eventId" element={<EventDetails />} />
      <Route path="/evento/:eventId" element={<EventDay />} />
      <Route path="/evento/:eventId/:videoId" element={<EventVideo />} />
      <Route
        path="/addEventContent/:eventId"
        element={
          <RequireAuth>
            <EventContent />
          </RequireAuth>
        }
      />
      <Route
        path="/acaolflix/:videoId"
        element={
          <RequireAuth>
            <WatchVideo />
          </RequireAuth>
        }
      />
      <Route
        path="/profile"
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />

      <Route path="/news" element={<News />} />
      <Route path="/news/:newsId" element={<News />} />
      <Route
        path="/allUsers"
        element={
          <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
            <AllUsers />
          </RequireAuth>
        }
      />
      <Route
        path="/allNews"
        element={
          <RequireAuth
            requiredRole={[
              process.env.REACT_APP_ADMINISTRATOR,
              process.env.REACT_APP_NEWS,
            ]}
          >
            <AllNews />
          </RequireAuth>
        }
      />
      <Route
        path="/createNews"
        element={
          <RequireAuth
            requiredRole={[
              process.env.REACT_APP_ADMINISTRATOR,
              process.env.REACT_APP_NEWS,
            ]}
          >
            <CreateNews />
          </RequireAuth>
        }
      />
      <Route
        path="/allSponsors"
        element={
          <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
            <AllSponsors />
          </RequireAuth>
        }
      />
      <Route
        path="/allEvents"
        element={
          <RequireAuth
            requiredRole={[
              process.env.REACT_APP_ADMINISTRATOR,
              process.env.REACT_APP_CULTURAL,
            ]}
          >
            <AllEvents />
          </RequireAuth>
        }
      />
      <Route
        path="/allVideos"
        element={
          <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
            <AllVideos />
          </RequireAuth>
        }
      />
      <Route
        path="/createSponsor"
        element={
          <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
            <CreateSponsor />
          </RequireAuth>
        }
      />
      <Route
        path="/createSponsor/:sponsorId"
        element={
          <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
            <CreateSponsor />
          </RequireAuth>
        }
      />
      <Route
        path="/createVideo"
        element={
          <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
            <CreateVideo />
          </RequireAuth>
        }
      />
      <Route
        path="/createVideo/:videoId"
        element={
          <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
            <CreateVideo />
          </RequireAuth>
        }
      />
      <Route
        path="/createEvent"
        element={
          <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
            <CreateEvent />
          </RequireAuth>
        }
      />
      <Route
        path="/createEvent/:eventId"
        element={
          <RequireAuth requiredRole={[process.env.REACT_APP_ADMINISTRATOR]}>
            <CreateEvent />
          </RequireAuth>
        }
      />
      <Route
        path="/eventInscriptions/:eventId"
        element={
          <RequireAuth
            requiredRole={[
              process.env.REACT_APP_ADMINISTRATOR,
              process.env.REACT_APP_CULTURAL,
            ]}
          >
            <EventInscriptions />
          </RequireAuth>
        }
      />
    </Routes>
  </Router>
);

export default router;
