import { DeleteIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Badge,
  Button,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FaYoutube } from "react-icons/fa";

const VideoItem = ({ video, onDelete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const split = process.env.REACT_APP_S3_VIDEOSIMAGES.split("/");

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{video.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex w={"100%"} gap={3} flexDir={"column"}>
              <Text>Por {video.authorName}</Text>
              <Image
                src={`https://${split[0]}.s3.amazonaws.com/${split[1]}/${video?.image}`}
                objectFit={"cover"}
              />
              <Flex>
                {video?.tags &&
                  video.tags.map((el) => (
                    <Badge colorScheme="green" p={1} borderRadius={3}>
                      {el}
                    </Badge>
                  ))}
              </Flex>
              <Link to={video.url}>Ir para Vídeo publicado</Link>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Voltar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Tr>
        <Td>{video.name}</Td>
        <Td>{video.eventName}</Td>
        <Td>{video.authorName}</Td>
        <Td>
          <Flex gap={5}>
            <Link to={video.url}>
              <Tooltip label={"Ir para o vídeo"}>
                <IconButton
                  colorScheme="red"
                  aria-label="Vai para o vídeo"
                  size="sm"
                  icon={<FaYoutube />}
                />
              </Tooltip>
            </Link>
            <Tooltip label={"Detalhes do vídeo"}>
              <IconButton
                colorScheme="blue"
                aria-label="Detalhes"
                size="sm"
                onClick={onOpen}
                icon={<SearchIcon />}
              />
            </Tooltip>
            <Tooltip label={"Excluir vídeo"}>
              <IconButton
                colorScheme="red"
                aria-label="Deletar"
                size="sm"
                isLoading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  onDelete(video.videoId);
                }}
                icon={<DeleteIcon />}
              />
            </Tooltip>
          </Flex>
        </Td>
      </Tr>
    </>
  );
};
export default VideoItem;
