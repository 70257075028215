import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import client from "../utils/client";
import Layout from "../components/Layout";
import {
  Box,
  Button,
  Flex,
  Heading,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import * as XLSX from "xlsx";

const EventInscriptions = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [listeners, setListeners] = useState([]);
  const [speakers, setSpeakers] = useState([]);

  useEffect(() => {
    if (eventId) {
      setIsLoading(true);
      const request = async () => {
        const response = await client.get(`events/${eventId}`);
        if (response.data) {
          setEvent(response.data);
          console.log(response.data);
          const users = await client.get(`users`);
          if (users.data) {
            const newSpeakers = response.data.speakers.map((speaker) => {
              const speakerData = users.data.find(
                (user) => user.userId === speaker.speakerId
              );
              if (speakerData) {
                return { ...speaker, ...speakerData };
              } else {
                return { ...speaker };
              }
            });
            setSpeakers(
              newSpeakers.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
            );
            const newListeners = response.data.listeners.map((listener) => {
              const listenerData = users.data.find(
                (user) => user.userId === listener.listenerId
              );
              if (listenerData) {
                return { ...listener, ...listenerData };
              } else {
                return { ...listener };
              }
            });
            setListeners(
              newListeners.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
            );
          }
        }
      };
      try {
        request().then(() => setIsLoading(false));
      } catch (err) {
        console.log(err);
      }
    }
  }, [eventId]);

  const exportListenersXLSX = () => {
    const exportFile = listeners.map((el) => ({
      "Tipo de inscrição": el?.inscriptionType,
      Irmão: el?.name,
      Grau:
        el?.symbolicGrade === "3"
          ? "Mestre"
          : el.symbolicGrade === "2"
          ? "Companheiro"
          : "Aprendiz",
      "Maçom ativo?": el?.isActiveMason === "true" ? "Ativo" : "Adormecido",
      Telefone: el?.phoneNumber,
      "E-mail": el?.email,
      Obediência: el?.obedience,
      Loja: el?.lodge,
      CIM: el?.cim,
      Aniversário: el?.bithdate,
      Rito: el?.rite,
      Estado: el?.state,
      Cidade: el?.city,
      Endereço: el?.address,
      CEP: el?.cep,
      Ocupação: el?.job,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportFile);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Participantes");
    XLSX.writeFile(workbook, `participantes-evento-${event?.name}.xlsx`);
  };

  const exportSpeakersXLSX = () => {
    const exportFile = speakers.map((el) => ({
      Irmão: el?.name,
      "Grau do Irmão":
        el?.symbolicGrade === "3"
          ? "Mestre"
          : el?.symbolicGrade === "2"
          ? "Companheiro"
          : "Aprendiz",
      "Maçom ativo?": el?.isActiveMason === "true" ? "Ativo" : "Adormecido",
      "Título da Palestra": el?.lectureName,
      "Descrição da Palestra": el?.lectureDescription,
      "Restrição da Palestra":
        el?.gradeRestriction === "1"
          ? "Sem Restrição"
          : el?.gradeRestriction === "2"
          ? "CComp e MM"
          : "Só Mestres",
      Telefone: el?.phoneNumber,
      "E-mail": el?.email,
      Obediência: el?.obedience,
      Loja: el?.lodge,
      CIM: el?.cim,
      Aniversário: el?.bithdate,
      Rito: el?.rite,
      Estado: el?.state,
      Cidade: el?.city,
      Endereço: el?.address,
      CEP: el?.cep,
      Ocupação: el?.job,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportFile);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Palestrantes");
    XLSX.writeFile(workbook, `palestrantes-evento-${event?.name}.xlsx`);
  };

  return (
    <Layout>
      <Flex justifyContent={"center"}>
        <Box
          w={["90%", "80%", "80%", "70%"]}
          padding="6"
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Skeleton isLoaded={!isLoading}>
            <Heading
              fontFamily={"Afacad"}
              variant={"outline"}
              colorScheme="green"
              textDecor={"underline"}
              textDecorationColor={"green"}
            >
              Inscritos {event?.name}
            </Heading>

            <Flex alignItems={"center"} flexDir={"column"}>
              <Flex
                w={"100%"}
                gap={5}
                wrap={"wrap"}
                justifyContent={"center"}
                mt={5}
              >
                <Flex gap={5} alignItems={"center"}>
                  <Heading fontSize={"1.2rem"}>Ouvintes</Heading>
                  <Button colorScheme="whatsapp" onClick={exportListenersXLSX}>
                    Baixar em Excel
                  </Button>
                </Flex>

                <TableContainer w={"100%"}>
                  <Table size={"sm"} variant="striped" colorScheme="green">
                    <Thead>
                      <Tr>
                        <Th>Irmão</Th>
                        <Th>Inscrição</Th>
                        <Th>Cidade - Estado</Th>
                        <Th>Obediência</Th>
                        <Th>Loja</Th>
                        <Th>Rito</Th>
                      </Tr>
                    </Thead>

                    <Tbody>
                      {listeners[0]?.userId &&
                        listeners.map((el) => (
                          <Tr key={el.userId}>
                            <Td>{el.name}</Td>
                            <Td>{el.inscriptionType}</Td>
                            <Td>
                              {el.city} - {el.state}
                            </Td>
                            <Td>{el.obedience}</Td>
                            <Td>{el.lodge}</Td>
                            <Td>{el.rite}</Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Flex>

              <Flex
                w={"100%"}
                gap={5}
                wrap={"wrap"}
                justifyContent={"center"}
                mt={5}
              >
                <Flex gap={5} alignItems={"center"}>
                  <Heading fontSize={"1.2rem"}>Palestrantes</Heading>
                  <Button colorScheme="whatsapp" onClick={exportSpeakersXLSX}>
                    Baixar em Excel
                  </Button>
                </Flex>

                <TableContainer w={"100%"}>
                  <Table size={"sm"} variant="striped" colorScheme="green">
                    <Thead>
                      <Tr>
                        <Th>Irmão</Th>
                        <Th>Título Palestra</Th>
                        <Th>Descrição</Th>
                        <Th>Restrição</Th>
                      </Tr>
                    </Thead>

                    <Tbody>
                      {speakers[0]?.userId &&
                        speakers.map((el) => (
                          <Tr key={el.userId + speakers.indexOf(el)}>
                            <Td>{el.name}</Td>
                            <Td>{el.lectureName}</Td>
                            <Td>{el.lectureDescription}</Td>
                            <Td>
                              {el.gradeRestriction === "1"
                                ? "Sem Restrição"
                                : el.gradeRestriction === "2"
                                ? "CComp e MM"
                                : "Só Mestres"}
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Flex>
            </Flex>
          </Skeleton>
        </Box>
      </Flex>
    </Layout>
  );
};
export default EventInscriptions;
