import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Flex, Box, Skeleton, Heading, Text, Button } from "@chakra-ui/react";
import { useParams, Link } from "react-router-dom";
import client from "../utils/client";
import { useAuth } from "../context/AuthContext";
import ReactPlayer from "react-player/youtube";

const EventVideo = () => {
  const [event, setEvent] = useState();
  const [video, setVideo] = useState();
  const { eventId, videoId } = useParams();
  const auth = useAuth();

  useEffect(() => {
    const request = async () => {
      const response = await client.get(`/events/${eventId}`);
      if (response.data) {
        setEvent(response.data);
        setVideo(response.data.videos.filter((el) => el.videoId === videoId));
      }
    };

    request();
  }, [videoId, eventId]);

  console.log(event);

  return (
    <Layout>
      <Flex alignItems={"center"} flexDir={"column"}>
        <Box
          w={["90%", "90%", "90%", "90%"]}
          p={[2, 6]}
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Skeleton isLoaded={event}>
            {video &&
            (Number(auth.user?.symbolicGrade) >=
              Number(video[0]?.gradeRestriction) ||
              event.isOpen) ? (
              <Flex
                flexDir={["column", "column", "column", "row"]}
                alignItems={"center"}
                w={"100%"}
                gap={[5, 5]}
                p={5}
              >
                <Box display={["flex", "flex", "flex", "none"]}>
                  <ReactPlayer
                    width={"100%"}
                    controls={true}
                    url={video[0]?.url}
                  />
                </Box>
                <Box display={["none", "none", "none", "flex"]}>
                  <ReactPlayer controls={true} url={video[0]?.url} />
                </Box>

                <Flex
                  flexDir={"column"}
                  gap={5}
                  w={["100%", "100%", "100%", "50%"]}
                  alignItems={"center"}
                >
                  <Heading
                    fontFamily={"Afacad"}
                    variant={"outline"}
                    colorScheme="green"
                    textDecor={"underline"}
                    textDecorationColor={"green"}
                  >
                    {video[0]?.name}
                  </Heading>
                  <Text fontSize={"1.4rem"} fontStyle={"italic"}>
                    Por: {video[0]?.author}
                  </Text>
                  <Text fontSize={"1.2rem"}>Evento: {event?.name}</Text>

                  <Link to={`/evento/${eventId}`}>
                    <Button colorScheme="blue">Voltar</Button>
                  </Link>
                </Flex>
                <Box mt={5}></Box>
              </Flex>
            ) : (
              "Seu grau ainda não pode assistir esta palestra!"
            )}
          </Skeleton>
        </Box>
      </Flex>
    </Layout>
  );
};
export default EventVideo;
