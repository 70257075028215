import { Box, Flex, Heading, Skeleton } from "@chakra-ui/react";
import Layout from "../components/Layout";
import SponsorForm from "../components/SponsorForm";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import client from "../utils/client";

const CreateSponsor = () => {
  const { sponsorId } = useParams();
  const [sponsor, setSponsor] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (sponsorId) {
      setIsLoading(true);
      const request = async () => {
        const response = await client.get(`sponsors/${sponsorId}`);
        response.data && setSponsor(response.data);
      };
      try {
        request().then(() => setIsLoading(false));
      } catch (error) {
        console.log(error);
      }
    }
  }, [sponsorId]);

  return (
    <Layout>
      <Flex justifyContent={"center"}>
        <Box
          w={["90%", "80%", "80%", "70%"]}
          padding="6"
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Heading
            fontFamily={"Afacad"}
            variant={"outline"}
            colorScheme="green"
            textDecor={"underline"}
            textDecorationColor={"green"}
          >
            {!sponsorId ? "Criar Patrocinador" : "Editar Patrocinador"}
          </Heading>
          <Flex alignItems={"center"} flexDir={"column"}>
            <Box w={"100%"}>
              <Skeleton isLoaded={!isLoading}>
                {!sponsorId ? (
                  <SponsorForm />
                ) : (
                  sponsor && <SponsorForm sponsor={sponsor} />
                )}
              </Skeleton>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Layout>
  );
};

export default CreateSponsor;
