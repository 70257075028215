import { Box, Flex, Heading } from "@chakra-ui/react";
import RegisterForm from "../components/RegisterForm";
import Layout from "../components/Layout";

const Register = () => {
  return (
    <Layout>
      <Flex justifyContent={"center"}>
        <Box
          w={["90%", "80%", "80%", "70%"]}
          padding="6"
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Heading
            fontFamily={"Afacad"}
            variant={"outline"}
            colorScheme="green"
            textDecor={"underline"}
            textDecorationColor={"green"}
          >
            Criar Perfil
          </Heading>
          <Flex alignItems={"center"} flexDir={"column"}>
            <Box w={"100%"}>
              <RegisterForm />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Layout>
  );
};

export default Register;
