import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import {
  Flex,
  Box,
  Skeleton,
  Heading,
  Card,
  CardBody,
  Stack,
  Text,
  Divider,
  CardFooter,
  Button,
} from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import client from "../utils/client";

const EventDay = () => {
  const [event, setEvent] = useState();
  const [hours, setHours] = useState([]);
  const [dataEvento, setDataEvento] = useState();
  const { eventId } = useParams();
  const auth = useAuth();

  useEffect(() => {
    const request = async () => {
      const response = await client.get(`/events/${eventId}`);
      if (response.data) {
        setEvent(response.data);

        // Extrai os horários de liberação dos vídeos
        const liberationTimes = response.data.videos.reduce(
          (accumulator, currentValue) => {
            if (!accumulator.includes(currentValue.liberationTime)) {
              accumulator.push(currentValue.liberationTime);
            }
            return accumulator;
          },
          []
        );

        // Ordena os horários de liberação
        liberationTimes.sort((a, b) => {
          const [hourA, minuteA] = a.split(":").map(Number);
          const [hourB, minuteB] = b.split(":").map(Number);
          if (hourA === hourB) {
            return minuteA - minuteB;
          } else {
            return hourA - hourB;
          }
        });
        setHours(liberationTimes);

        // Ajusta a data do evento
        setDataEvento(
          new Date(
            new Date(response.data.eventDate).setHours(
              new Date(response.data.eventDate).getHours() + 3
            )
          )
        );
      }
    };
    request();
  }, [eventId]);

  const isTimePassed = (videoTime) => {
    const [videoHour, videoMinute] = videoTime.split(":").map(Number);
    const now = new Date();
    const videoDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      videoHour,
      videoMinute
    );

    return now.getTime() >= videoDate.getTime(); // Compara os timestamps
  };

  return (
    <Layout>
      <Flex alignItems={"center"} flexDir={"column"}>
        <Box
          w={["90%", "90%", "90%", "90%"]}
          padding="6"
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Skeleton isLoaded={event}>
            {event && (
              <Flex
                flexDir={["column", "row"]}
                alignItems={"center"}
                w={"100%"}
                gap={[5, 5]}
                p={5}
              >
                <Flex
                  flexDir={"column"}
                  gap={5}
                  w={["100%"]}
                  alignItems={"center"}
                >
                  <Heading
                    fontFamily={"Afacad"}
                    variant={"outline"}
                    colorScheme="green"
                    textDecor={"underline"}
                    textDecorationColor={"green"}
                  >
                    {event?.name}
                  </Heading>

                  {dataEvento > new Date() ? (
                    <p>Evento não iniciado</p>
                  ) : (
                    hours?.map(
                      (hour) =>
                        isTimePassed(hour) && (
                          <Flex
                            key={hour}
                            flexDir={"column"}
                            alignItems={"center"}
                          >
                            <Heading fontSize={"1.5rem"}>
                              Faixa: {hours.indexOf(hour) + 1} - {hour}
                            </Heading>
                            <Flex
                              gap={2}
                              wrap={"wrap"}
                              justifyContent={"center"}
                              w={"100%"}
                            >
                              {event.videos.map((el) => {
                                if (
                                  el.liberationTime === hour &&
                                  Number(el.gradeRestriction) <=
                                    Number(auth.user?.symbolicGrade)
                                ) {
                                  return (
                                    <Card
                                      key={el.videoId}
                                      w="xs"
                                      boxShadow="xl"
                                    >
                                      <CardBody>
                                        <Stack mt="6" spacing="3">
                                          <Heading size="md">
                                            {el?.name}
                                          </Heading>
                                          <Text fontStyle={"italic"}>
                                            {el?.author}
                                          </Text>
                                        </Stack>
                                      </CardBody>
                                      <Divider />
                                      <CardFooter>
                                        <Flex
                                          w={"100%"}
                                          justifyContent={"center"}
                                        >
                                          <Link to={`./${el.videoId}`}>
                                            <Button
                                              variant="solid"
                                              colorScheme="blue"
                                            >
                                              Assistir
                                            </Button>
                                          </Link>
                                        </Flex>
                                      </CardFooter>
                                    </Card>
                                  );
                                }
                                return null;
                              })}
                            </Flex>
                          </Flex>
                        )
                    )
                  )}
                </Flex>
              </Flex>
            )}
          </Skeleton>
        </Box>
      </Flex>
    </Layout>
  );
};

export default EventDay;
