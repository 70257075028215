import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  useToast,
} from "@chakra-ui/react";
import Layout from "../components/Layout";

import { useForm } from "react-hook-form";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDropzone } from "react-dropzone";
import { useAuth } from "../context/AuthContext";
import { DeleteIcon } from "@chakra-ui/icons";
import { myBucket } from "../utils/bucket";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import client from "../utils/client";

const CreateNews = () => {
  const { register, handleSubmit } = useForm();
  const [text, setText] = useState();
  const auth = useAuth();
  const toast = useToast();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const uploadFile = async (file, photoId) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: process.env.REACT_APP_S3_NEWSIMAGES,
      Key: photoId,
    };

    myBucket.putObject(params, (err, data) => {
      if (err) console.log(err);
    });
  };

  const onDrop = (acceptedFiles) => {
    const newImage = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setImages([...images, ...newImage]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const onDelete = (preview) => {
    const updatedImages = images.filter((image) => image.preview !== preview);
    setImages(updatedImages);
  };

  const onSubmit = async (el) => {
    setIsLoading(true);
    if (images[0]) {
      try {
        let imagesUrls = [];

        for (const img of images) {
          const imageId = `newsPhoto-${v4()}.jpeg`;
          await uploadFile(img, imageId);
          imagesUrls.push(imageId);
        }
        await client.post("/news", {
          title: el.title,
          authorId: auth.user.id,
          text: text,
          images: imagesUrls,
        });
        toast({
          title: "Notícia criada com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        navigate("/allNews");
      } catch (error) {
        console.log(error);
        toast({
          title: "Algum erro aconteceu",
          description: "Contate o Administrador do Sistema",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } else {
      toast({
        title: "Faltou a foto!",
        description:
          "Adicione pelo menos uma imagem para aparecer na capa do site!",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Layout>
      <Flex justifyContent={"center"}>
        <Box
          w={["90%", "80%", "80%", "70%"]}
          padding="6"
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Heading
            fontFamily={"Afacad"}
            variant={"outline"}
            colorScheme="green"
            textDecor={"underline"}
            textDecorationColor={"green"}
          >
            Criar Postagem
          </Heading>
          <Flex mt={5} alignItems={"center"} flexDir={"column"}>
            <Box w={"100%"}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Flex
                  gap={5}
                  w={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDir={"column"}
                >
                  <Input
                    {...register("title")}
                    placeholder="Título da Notícia"
                  />

                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        ["link"],
                        ["clean"],
                      ],
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "indent",
                      "link",
                    ]}
                    value={text}
                    onChange={setText}
                    style={{
                      width: "100%",
                      fontFamily: "Afacad",
                    }}
                  />

                  <div
                    {...getRootProps()}
                    style={{
                      margin: "20px 0",
                    }}
                  >
                    <Flex
                      justifyContent={"center"}
                      bgColor={"#ccc"}
                      p={5}
                      borderRadius={8}
                      minW={"50%"}
                    >
                      <input {...getInputProps()} />
                      <p>
                        {isDragActive
                          ? "Solte as imagens aqui..."
                          : "Arraste e solte imagens aqui ou clique para selecionar"}
                      </p>
                    </Flex>
                  </div>

                  <Flex gap={3} wrap={"wrap"}>
                    {images.map((file) => (
                      <Flex
                        flexDir={"column-reverse"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={1}
                        my={3}
                      >
                        <IconButton
                          shadow={"dark-lg"}
                          colorScheme="red"
                          aria-label="Detalhes"
                          size="sm"
                          onClick={() => {
                            onDelete(file.preview);
                          }}
                          icon={<DeleteIcon />}
                        />
                        <Image
                          shadow={"dark-lg"}
                          borderRadius={8}
                          key={file?.preview}
                          src={file?.preview}
                          alt={file?.name}
                          maxW={"100%"}
                          maxH={"200px"}
                        />
                      </Flex>
                    ))}
                  </Flex>
                  <Button
                    isLoading={isLoading}
                    type="submit"
                    colorScheme="blue"
                  >
                    Adicionar Notícia
                  </Button>
                </Flex>
              </form>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Layout>
  );
};

export default CreateNews;
