import { createContext, useContext, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { setInStorage, login } from "../utils/auth";
import NotAuthorized from "../routes/NotAuthorized";

const AuthContext = createContext(null);

export const AuthProvider = (props) => {
  const userStored = localStorage.getItem("user");
  const photoStored = localStorage.getItem("userPhoto");
  const [user, setUser] = useState(userStored ? JSON.parse(userStored) : null);
  const [userPhoto, setUserPhoto] = useState(
    photoStored ? JSON.parse(photoStored) : null
  );

  const signin = async (data, callback) => {
    try {
      const response = await login(data);
      const { id, token, type, photo, symbolicGrade } = response.data;
      const user = {
        id,
        token,
        username: response.data.name,
        type,
        photo,
        symbolicGrade,
      };
      setInStorage("user", user);
      setUser(user);

      const split = process.env.REACT_APP_S3_BUCKET.split("/");

      setUserPhoto(`https://${split[0]}.s3.amazonaws.com/${split[1]}/${photo}`);
      setInStorage(
        "userPhoto",
        `https://${split[0]}.s3.amazonaws.com/${split[1]}/${photo}`
      );
      callback();
    } catch (error) {
      alert("Usuário ou senha inválidos");
    }
  };

  const logout = (callback) => {
    localStorage.removeItem("user");
    localStorage.removeItem("userPhoto");
    setUser(null);
    setUserPhoto(null);
    callback();
  };

  return (
    <AuthContext.Provider
      {...props}
      value={{ user, signin, logout, userPhoto }}
    />
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const RequireAuth = ({ children, requiredRole }) => {
  const auth = useAuth();
  const location = useLocation();

  let ok = false;

  if (!auth.user?.id) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (!requiredRole) {
    return children;
  }

  if (requiredRole) {
    requiredRole.forEach((role) => {
      if (role && auth.user?.type === role) {
        ok = true;
      }
    });
  }

  if (ok) {
    return children;
  }

  return <NotAuthorized />;
};
