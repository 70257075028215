import {
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useAuth } from "../../context/AuthContext";
import { useForm } from "react-hook-form";
import client from "../../utils/client";
import { useState } from "react";
import { DeleteIcon } from "@chakra-ui/icons";

const Comments = ({ comments, videoId, setReload, reload }) => {
  const auth = useAuth();
  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const response = await client.put(`/videos/addComment/${videoId}`, data);
    if (response.data) {
      reset();
      setLoading(false);
      setReload(!reload);
    }
  };

  const handleDelete = async (com) => {
    setDeleteLoading(true);
    const response = await client.put(`videos/removeComment/${videoId}/${com}`);
    if (response.data) {
      setReload(!reload);
      setDeleteLoading(false);
    }
  };

  return (
    <Flex flexDir={"column"} w={["100%", "80%"]} mx={"auto"} gap={[5]}>
      <Heading
        fontFamily={"Afacad"}
        variant={"outline"}
        colorScheme="green"
        textDecor={"underline"}
        textDecorationColor={"green"}
        fontSize={"1.5rem"}
      >
        Comentários
      </Heading>
      <Flex flexDir={"column"} gap={3}>
        {comments &&
          comments.map((com, index) => (
            <Flex
              key={index}
              p={[3, 5]}
              border={"1px solid #ccc"}
              boxShadow={"md"}
              borderRadius={6}
              gap={2}
              flexDir={"column"}
            >
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Heading fontFamily={"Afacad"} fontSize={"1rem"}>
                  {com.authorName}
                </Heading>
                {(auth.user.id === com.authorId ||
                  auth.user.type === process.env.REACT_APP_ADMINISTRATOR) && (
                  <IconButton
                    size={"xs"}
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    isLoading={deleteLoading}
                    onClick={() => handleDelete(index)}
                  />
                )}
              </Flex>
              <Text p={2}>{com.comment}</Text>
            </Flex>
          ))}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex
            p={5}
            border={"1px solid #ccc"}
            boxShadow={"md"}
            borderRadius={6}
            gap={2}
            flexDir={"column"}
          >
            <Heading fontFamily={"Afacad"} fontSize={"1rem"}>
              Deixe seu Comentário
            </Heading>

            <Textarea p={2} {...register("comment")}></Textarea>
            <Input
              {...register("authorId")}
              type="hidden"
              value={auth.user.id}
            />
            <Button
              type="submit"
              alignSelf={"flex-end"}
              w={["70%", "20%"]}
              colorScheme="green"
              isLoading={loading}
            >
              Comentar
            </Button>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
};

export default Comments;
