import {
  Button,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { TbMilitaryRank } from "react-icons/tb";
import { GiRank1 } from "react-icons/gi";
import client from "../../utils/client";
import { useState } from "react";
import { SearchIcon } from "@chakra-ui/icons";

const UserCard = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const split = process.env.REACT_APP_S3_BUCKET.split("/");

  const userType =
    user.type === process.env.REACT_APP_ADMINISTRATOR
      ? "Administrador"
      : user.type === process.env.REACT_APP_NEWS
      ? "Notícias"
      : user.type === process.env.REACT_APP_MASON
      ? "Maçom"
      : user.type === process.env.REACT_APP_CULTURAL
      ? "Comissão Cultural"
      : "Não-Maçom";

  const handlePromotion = async (newType) => {
    setIsLoading(true);
    const type = { type: newType };

    try {
      const response = await client.post(`/users/promote/${user.userId}`, type);
      if (response.data) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes do Usuário</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir={"column"} gap={1.5}>
              <Flex gap={2}>
                <Text fontWeight={700}>Nome: </Text>
                <Text>{user?.name}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Tipo: </Text>
                <Text>{userType}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Email: </Text>
                <Text>{user?.email}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Telefone: </Text>
                <Text>{user?.phoneNumber}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Oriente: </Text>
                <Text>
                  {user?.city} - {user?.state}
                </Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Endereço: </Text>
                <Text>{user?.address}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Trabalho: </Text>
                <Text>{user?.job}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>CPF: </Text>
                <Text>{user?.cpf}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Aniversário: </Text>
                <Text>{user?.bithdate}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Maçom ativo: </Text>
                <Text>
                  {user?.isActiveMason === "true" ? "Ativo" : "Adormecido"}
                </Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Loja: </Text>
                <Text>{user?.lodge}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Rito: </Text>
                <Text>{String(user?.rite).toUpperCase()}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Potência: </Text>
                <Text>{user?.obedience}</Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>Grau Simbólico: </Text>
                <Text>
                  {user?.symbolicGrade === "1"
                    ? "Aprendiz"
                    : user?.symbolicGrade === "2"
                    ? "Companheiro"
                    : "Mestre"}
                </Text>
              </Flex>
              <Flex gap={2}>
                <Text fontWeight={700}>CIM: </Text>
                <Text>{user?.cim}</Text>
              </Flex>
              <Flex justifyContent={"center"} w={"100%"}>
                <Image
                  src={`https://${split[0]}.s3.amazonaws.com/${split[1]}/${user?.photo}`}
                  borderRadius={"100%"}
                  w={"150px"}
                  h={"150px"}
                  objectFit={"cover"}
                />
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Tr>
        <Td>{user?.name}</Td>
        <Td>{user?.email}</Td>
        <Td>{user?.phoneNumber}</Td>
        <Td>{userType}</Td>
        <Td>
          <Flex gap={3}>
            <Tooltip label={"Detalhar usuário"}>
              <IconButton
                colorScheme="blue"
                aria-label="Detalhes"
                icon={<SearchIcon />}
                onClick={onOpen}
              />
            </Tooltip>
            {userType === "Maçom" && (
              <Tooltip label={"Promover para Criador de Notícias"}>
                <IconButton
                  colorScheme="green"
                  aria-label="Promover usuário"
                  icon={<TbMilitaryRank size={30} />}
                  onClick={() => handlePromotion(process.env.REACT_APP_NEWS)}
                  isLoading={isLoading}
                />
              </Tooltip>
            )}
            {userType === "Notícias" && (
              <Tooltip label={"Promover para Comissão Cultural"}>
                <IconButton
                  colorScheme="orange"
                  aria-label="Promover usuário"
                  icon={<TbMilitaryRank size={30} />}
                  onClick={() =>
                    handlePromotion(process.env.REACT_APP_CULTURAL)
                  }
                  isLoading={isLoading}
                />
              </Tooltip>
            )}
            {userType === "Comissão Cultural" && (
              <Tooltip label={"Promover para Administrador"}>
                <IconButton
                  colorScheme="purple"
                  aria-label="Promover usuário"
                  icon={<TbMilitaryRank size={30} />}
                  onClick={() =>
                    handlePromotion(process.env.REACT_APP_ADMINISTRATOR)
                  }
                  isLoading={isLoading}
                />
              </Tooltip>
            )}
            {userType === "Administrador" && (
              <Tooltip label={"Rebaixar para Maçom"}>
                <IconButton
                  colorScheme="red"
                  aria-label="Rebaixar usuário"
                  icon={<GiRank1 size={30} />}
                  onClick={() => handlePromotion(process.env.REACT_APP_MASON)}
                  isLoading={isLoading}
                />
              </Tooltip>
            )}
          </Flex>
        </Td>
      </Tr>
    </>
  );
};
export default UserCard;
