import {
  Box,
  Button,
  Flex,
  Heading,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import client from "../utils/client";
import VideoItem from "../components/VideoItem";

const AllVideos = () => {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [videosItems, setVideosItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);

  const auth = useAuth();
  const navigate = useNavigate();

  const handleDelete = async (videoId) => {
    await client.delete(`/videos/${videoId}`);
    setVideosItems(videosItems.filter((video) => video.videoId !== videoId));
  };

  useEffect(() => {
    setIsLoadingPage(true);
    const request = async () => {
      const response = await client.get(`/videos?page=${currentPage}`);
      setVideosItems(response.data.videoItems);
      setCurrentPage(response.data.currentPage);
      setTotalPages(
        Array.from(
          { length: response.data.totalPages },
          (_, index) => index + 1
        )
      );
    };
    request().then(() => setIsLoadingPage(false));
  }, [currentPage]);

  return (
    <Layout>
      {auth?.user.type === process.env.REACT_APP_ADMINISTRATOR ? (
        <Flex justifyContent={"center"}>
          <Box
            w={["90%", "80%", "80%", "70%"]}
            padding="6"
            boxShadow="lg"
            bg="white"
            mt={[8]}
          >
            <Skeleton isLoaded={!isLoadingPage}>
              <Heading
                fontFamily={"Afacad"}
                variant={"outline"}
                colorScheme="green"
                textDecor={"underline"}
                textDecorationColor={"green"}
              >
                Gerenciar Vídeos
              </Heading>

              <Flex alignItems={"center"} flexDir={"column"}>
                <Flex
                  w={"100%"}
                  gap={5}
                  wrap={"wrap"}
                  justifyContent={"center"}
                  mt={5}
                >
                  <Button
                    colorScheme="blue"
                    onClick={() => navigate("/createVideo")}
                  >
                    Novo Vídeo
                  </Button>

                  <TableContainer w={"100%"}>
                    <Table size={"sm"} variant="striped" colorScheme="green">
                      <Thead>
                        <Tr>
                          <Th>Título</Th>
                          <Th>Evento</Th>
                          <Th>Autor</Th>
                          <Th>Ações</Th>
                        </Tr>
                      </Thead>

                      <Tbody>
                        {!!videosItems &&
                          videosItems[0]?.videoId &&
                          videosItems.map((video) => (
                            <VideoItem
                              key={video.id}
                              onDelete={handleDelete}
                              video={video}
                            />
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
                <Flex gap={2} mt={5} alignItems={"center"}>
                  {!!totalPages &&
                    totalPages[0] &&
                    totalPages.map((page) => {
                      if (page === currentPage) {
                        return <Text mx={2}>{page}</Text>;
                      } else {
                        return (
                          <Button
                            key={page}
                            colorScheme="linkedin"
                            size="sm"
                            variant={page === currentPage ? "outline" : "solid"}
                            disabled={page === currentPage ? "true" : "false"}
                            onClick={() => setCurrentPage(page)}
                          >
                            {page}
                          </Button>
                        );
                      }
                    })}
                </Flex>
              </Flex>
            </Skeleton>
          </Box>
        </Flex>
      ) : (
        <p>NÃO AUTORIZADO</p>
      )}
    </Layout>
  );
};
export default AllVideos;
