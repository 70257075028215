import { useEffect, useState } from "react";
import client from "../../utils/client";
import { Button, Flex, Skeleton, Text } from "@chakra-ui/react";
import EventCard from "../EventCard";

const OtherEvents = ({ publicEvents }) => {
  const [events, setEvents] = useState();
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);

  useEffect(() => {
    setIsLoadingPage(true);
    const request = async () => {
      const response = await client.get(`/events?page=${currentPage}`);
      setEvents(response.data.eventItems);
      setCurrentPage(response.data.currentPage);
      setTotalPages(
        Array.from(
          { length: response.data.totalPages },
          (_, index) => index + 1
        )
      );
    };
    request().then(() => setIsLoadingPage(false));
  }, [currentPage]);

  return (
    <Skeleton isLoaded={!isLoadingPage}>
      <Flex gap={[3, 6]} wrap={"wrap"} justifyContent={"center"}>
        {events &&
          events.map((el) => {
            if (!publicEvents || !!!el.isOpen) {
              const now = new Date();
              const addedTime = 27 * 60 * 60 * 1000;
              const modifiedDate = new Date(
                new Date(el.eventDate).getTime() + addedTime
              );

              if (modifiedDate > now) {
                return <EventCard key={el.id} event={el} />;
              }
              return "";
            }
            return "";
          })}
      </Flex>

      <Flex gap={[2]} mt={5} alignItems={"center"}>
        {totalPages[0] &&
          totalPages.map((page) => {
            if (page === currentPage) {
              return <Text mx={2}>{page}</Text>;
            } else {
              return (
                <Button
                  key={page}
                  colorScheme="linkedin"
                  size="sm"
                  variant={page === currentPage ? "outline" : "solid"}
                  disabled={page === currentPage ? "true" : "false"}
                  onClick={() => setCurrentPage(page)}
                >
                  {page}
                </Button>
              );
            }
          })}
      </Flex>
    </Skeleton>
  );
};
export default OtherEvents;
