import {
  Badge,
  Box,
  Button,
  Flex,
  FormLabel,
  IconButton,
  Image,
  Input,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 } from "uuid";
import { myBucket } from "../../utils/bucket";
import { MdAddAPhoto } from "react-icons/md";
import client from "../../utils/client";
import { useNavigate } from "react-router-dom";

const SponsorForm = ({ sponsor }) => {
  const { register, handleSubmit } = useForm();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const fileInput = useRef(null);
  const split = process.env.REACT_APP_S3_SPONSORIMAGES.split("/");
  const [newImage, setNewImage] = useState(
    `https://${split[0]}.s3.amazonaws.com/${split[1]}/${sponsor?.images[0]}` ||
      undefined
  );

  const uploadFile = async (file, imageId) => {
    const response = await fetch(file);
    const blob = await response.blob();
    const fileObj = new File([blob], file.name, { type: blob.type });
    const params = {
      ACL: "public-read",
      Body: fileObj,
      Bucket: process.env.REACT_APP_S3_SPONSORIMAGES,
      Key: imageId,
    };

    myBucket.putObject(params, (err, data) => {
      if (err) console.log(err);
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const logo = `sponsorLogo-${v4()}.jpeg`;
      uploadFile(newImage, logo);

      await client.post("/sponsors", {
        images: [logo],
        ...data,
      });

      toast({
        title: "Patrocinador cadastrado com sucesso",
        description: "O patrocinador foi cadastrado!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/allSponsors");
    } catch (error) {
      toast({
        title: "Algum erro aconteceu",
        description: "Contate o Administrador do Sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const onEdit = async (data) => {
    setIsLoading(true);
    try {
      let logo;
      if (newImage !== sponsor.images[0]) {
        logo = `sponsorLogo-${v4()}.jpeg`;
        uploadFile(newImage, logo);
      } else {
        logo = sponsor.images[0];
      }
      await client.patch(`/sponsors/${sponsor.sponsorId}`, {
        images: [logo],
        ...data,
      });
      toast({
        title: "Patrocinador alterado com sucesso",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/allSponsors");
    } catch (error) {
      console.log(error);
      toast({
        title: "Algum erro aconteceu",
        description: "Contate o Administrador do Sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const handleImage = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setNewImage(URL.createObjectURL(file));
    }
  };

  return (
    <form onSubmit={sponsor ? handleSubmit(onEdit) : handleSubmit(onSubmit)}>
      <Flex flexDir="column" gap="5" my={5}>
        <Box
          border={"1px solid #ccc"}
          borderRadius={"8px"}
          p={[2, 5]}
          bgColor={"white"}
        >
          <Badge fontSize={"1rem"} colorScheme="whatsapp" mb={2}>
            Informações do Patrocinador
          </Badge>
          <Flex flexDir={"column"} gap={3}>
            <Stack direction={["column", "row"]}>
              <FormLabel w={"100%"} m={0}>
                Nome do Patrocinador
                <Input
                  required
                  type="text"
                  placeholder="Nome do Patrocinador"
                  {...register("name")}
                  defaultValue={sponsor && sponsor.name}
                />
              </FormLabel>
            </Stack>
            <Stack direction={["column", "row"]}>
              <FormLabel w={"100%"} m={0}>
                Link de destino
                <Input
                  required
                  type="text"
                  placeholder="http://..."
                  {...register("targetUrl")}
                  defaultValue={sponsor && sponsor.targetUrl}
                />
              </FormLabel>
            </Stack>
            <Stack direction={["column", "row"]}>
              <FormLabel w={"100%"} m={0}>
                Tipo de Patrocinador
                <Select required {...register("type")}>
                  <option value="" disabled selected={!sponsor}>
                    Selecione o tipo
                  </option>
                  <option
                    selected={sponsor && sponsor.type === "Principal"}
                    value="Principal"
                  >
                    Principal
                  </option>
                  <option
                    selected={sponsor && sponsor.type === "Secundário"}
                    value="Secundário"
                  >
                    Secundário
                  </option>
                </Select>
              </FormLabel>
              <FormLabel w={"100%"} m={0}>
                Status
                <Select required {...register("isActive")}>
                  <option
                    selected={sponsor && sponsor.type === "Ativo"}
                    value="Ativo"
                  >
                    Ativo
                  </option>
                  <option
                    selected={sponsor && sponsor.type === "Inativo"}
                    value="Inativo"
                  >
                    Inativo
                  </option>
                </Select>
              </FormLabel>
              <FormLabel w={"100%"} m={0}>
                Término do Patrocínio
                <Input
                  type="date"
                  {...register("endDate")}
                  defaultValue={sponsor && sponsor?.endDate}
                />
              </FormLabel>
            </Stack>
          </Flex>
        </Box>

        <Box
          p={[2, 5]}
          bgColor={"white"}
          border={"1px solid #ccc"}
          borderRadius={"8px"}
        >
          <Badge fontSize={"1rem"} colorScheme="whatsapp" mb={2}>
            Imagem do patrocinador para ser veiculada no Site
          </Badge>
          <Flex
            flexDir={["column", "row"]}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={5}
          >
            <Flex flexDir={["column", "row"]} w={["100%"]}>
              <div>
                <Input
                  type="file"
                  accept="image/*"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleImage}
                  ref={fileInput}
                />
                <label htmlFor="fileInput">
                  <Flex alignItems={"center"} gap={10}>
                    <FormLabel m={0}>
                      Adicione a imagem do Patrocinador
                    </FormLabel>
                    <IconButton
                      colorScheme="whatsapp"
                      borderRadius={"100%"}
                      w={["70px", "85px"]}
                      h={["70px", "85px"]}
                      aria-label="Upload File"
                      icon={<MdAddAPhoto fontSize={"45px"} />}
                      onClick={() => fileInput.current.click()}
                    />
                  </Flex>
                </label>
              </div>
            </Flex>
            <Flex justifyContent={"center"} w={"100%"}>
              {newImage && <Image objectFit={"cover"} src={newImage} />}
            </Flex>
          </Flex>
        </Box>

        <Flex justifyContent={"center"}>
          <Button
            isLoading={isLoading}
            colorScheme="whatsapp"
            w={["100%", "40%"]}
            type="submit"
          >
            Cadastrar Patrocinador
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default SponsorForm;
