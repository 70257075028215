import { Box, Flex, Heading, Text } from "@chakra-ui/react";

const CarouselItem = ({ title, text, imageUrl, noBlackImage, imageFit }) => {
  const sanitizeHTML = (htmlString) => {
    return htmlString.replace(/<[^>]*>/g, "");
  };
  return (
    <Box
      minH={"44vh"}
      maxH={"60vh"}
      backgroundImage={
        noBlackImage
          ? `url(${imageUrl})`
          : `linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 200%), url(${imageUrl})`
      }
      backgroundSize={imageFit ? "contain" : "cover"}
      bgRepeat={"no-repeat"}
      backgroundPosition="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
      color="white"
      fontFamily="Arial, sans-serif"
    >
      {title && (
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          gap={5}
          textAlign="center"
          zIndex="1"
        >
          <Heading as="h1" fontSize="2xl">
            {title}
          </Heading>
          <Text w={"70%"}>{sanitizeHTML(String(text).slice(0, 100))}...</Text>
        </Flex>
      )}
    </Box>
  );
};
export default CarouselItem;
