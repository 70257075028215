import {
  Box,
  Button,
  Flex,
  Heading,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import client from "../utils/client";
import EventItem from "../components/EventItem";

const AllEvents = () => {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [eventsItems, setEventsItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [reload, setReload] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoadingPage(true);
    const request = async () => {
      const response = await client.get(`/events?page=${currentPage}`);
      setEventsItems(response.data.eventItems);
      setCurrentPage(response.data.currentPage);
      setTotalPages(
        Array.from(
          { length: response.data.totalPages },
          (_, index) => index + 1
        )
      );
    };
    request().then(() => setIsLoadingPage(false));
    setIsLoadingPage(false);
  }, [currentPage, reload]);

  const handleDelete = async (eventId) => {
    await client.delete(`/events/${eventId}`);
    setEventsItems(eventsItems.filter((event) => event.eventId !== eventId));
    setReload(!reload);
  };

  return (
    <Layout>
      <Flex justifyContent={"center"}>
        <Box
          w={["90%", "80%", "80%", "70%"]}
          padding="6"
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Skeleton isLoaded={!isLoadingPage}>
            <Heading
              fontFamily={"Afacad"}
              variant={"outline"}
              colorScheme="green"
              textDecor={"underline"}
              textDecorationColor={"green"}
            >
              Gerenciar Eventos
            </Heading>

            <Flex alignItems={"center"} flexDir={"column"}>
              <Flex
                w={"100%"}
                gap={5}
                wrap={"wrap"}
                justifyContent={"center"}
                mt={5}
              >
                <Button
                  colorScheme="blue"
                  onClick={() => navigate("/createEvent")}
                >
                  Novo Evento
                </Button>

                <TableContainer w={"100%"}>
                  <Table size={"sm"} variant="striped" colorScheme="green">
                    <Thead>
                      <Tr>
                        <Th>Evento</Th>
                        <Th>Data do Evento</Th>
                        <Th>N° Palestrantes</Th>
                        <Th>N° Ouvintes</Th>
                        <Th>Ações</Th>
                      </Tr>
                    </Thead>

                    <Tbody>
                      {!!eventsItems &&
                        eventsItems[0]?.eventId &&
                        eventsItems.map((event) => (
                          <EventItem
                            key={event.id}
                            onDelete={handleDelete}
                            event={event}
                          />
                        ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Flex>
              <Flex gap={2} mt={5} alignItems={"center"}>
                {!!totalPages &&
                  totalPages[0] &&
                  totalPages.map((page) => {
                    if (page === currentPage) {
                      return <Text mx={2}>{page}</Text>;
                    } else {
                      return (
                        <Button
                          key={page}
                          colorScheme="linkedin"
                          size="sm"
                          variant={page === currentPage ? "outline" : "solid"}
                          disabled={page === currentPage ? "true" : "false"}
                          onClick={() => setCurrentPage(page)}
                        >
                          {page}
                        </Button>
                      );
                    }
                  })}
              </Flex>
            </Flex>
          </Skeleton>
        </Box>
      </Flex>
    </Layout>
  );
};
export default AllEvents;
