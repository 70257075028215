import { Link, useParams } from "react-router-dom";
import Layout from "../components/Layout";
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import client from "../utils/client";
import ReactPlayer from "react-player/youtube";
import { useAuth } from "../context/AuthContext";
import Comments from "../components/Comments";

const WatchVideo = () => {
  const { videoId } = useParams();
  const [video, setVideo] = useState();
  const auth = useAuth();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const request = async () => {
      const response = await client.get(`/videos/${videoId}`);
      response.data && setVideo(response.data);
    };
    request();
  }, [videoId, reload]);

  return (
    <Layout>
      <Flex alignItems={"center"} flexDir={"column"}>
        <Box
          w={["100%", "90%", "90%", "90%"]}
          p={[2, 6]}
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Skeleton isLoaded={video}>
            {video &&
            Number(auth.user.symbolicGrade) >=
              Number(video?.gradeRestriction) ? (
              <Flex
                flexDir={["column", "column", "column", "row"]}
                alignItems={"center"}
                w={"100%"}
                gap={[5, 5]}
                p={5}
              >
                <Box display={["flex", "flex", "flex", "none"]}>
                  <ReactPlayer
                    width={"100%"}
                    controls={true}
                    url={video?.url}
                  />
                </Box>
                <Box display={["none", "none", "none", "flex"]}>
                  <ReactPlayer controls={true} url={video?.url} />
                </Box>

                <Flex
                  flexDir={"column"}
                  gap={5}
                  w={["100%", "100%", "100%", "50%"]}
                  alignItems={"center"}
                >
                  <Heading
                    fontFamily={"Afacad"}
                    variant={"outline"}
                    colorScheme="green"
                    textDecor={"underline"}
                    textDecorationColor={"green"}
                  >
                    {video?.name}
                  </Heading>
                  <Text fontSize={"1.4rem"} fontStyle={"italic"}>
                    Por: {video?.authorName}
                  </Text>
                  <Text fontSize={"1.2rem"}>Evento: {video?.eventName}</Text>
                  <Flex gap={3}>
                    {video?.tags[0] &&
                      video.tags.map((tag) => (
                        <Badge colorScheme="green">{tag}</Badge>
                      ))}
                  </Flex>
                  <Link to={"/acaolflix"}>
                    <Button colorScheme="blue">Voltar</Button>
                  </Link>
                </Flex>
              </Flex>
            ) : (
              <p>Você não pode assistir esse vídeo ainda!</p>
            )}
          </Skeleton>
          <Box mt={5}>
            <Comments
              videoId={video?.videoId}
              reload={reload}
              setReload={setReload}
              comments={video?.comments || []}
            />
          </Box>
        </Box>
      </Flex>
    </Layout>
  );
};

export default WatchVideo;
