import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Badge,
  Box,
  Button,
  Flex,
  FormLabel,
  IconButton,
  Image,
  Input,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from "@chakra-ui/react";
import client from "../../utils/client";
import { myBucket } from "../../utils/bucket";
import { v4 } from "uuid";
import { MdAddAPhoto } from "react-icons/md";

const EventForm = ({ event }) => {
  const [isEventOpen, setIsEventOpen] = useState("false");
  const { register, handleSubmit } = useForm();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const split = process.env.REACT_APP_S3_EVENTSIMAGES.split("/");
  const navigate = useNavigate();
  const fileInput = useRef(null);
  const [newImage, setNewImage] = useState(
    event?.image ? event.image : undefined
  );

  const uploadFile = async (file, imageId) => {
    const response = await fetch(file);
    const blob = await response.blob();
    const fileObj = new File([blob], file.name, { type: blob.type });
    const params = {
      ACL: "public-read",
      Body: fileObj,
      Bucket: process.env.REACT_APP_S3_EVENTSIMAGES,
      Key: imageId,
    };

    myBucket.putObject(params, (err, data) => {
      if (err) console.log(err);
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const logo = `eventImage-${v4()}.jpeg`;
      uploadFile(newImage, logo);

      const {
        name,
        theme,
        eventDate,
        eventStartSubscriptionForSpeakers,
        eventEndSubscriptionForSpeakers,
        eventStartSubscriptionForListeners,
        eventEndSubscriptionForListeners,
      } = data;

      await client.post("/events", {
        image: logo,
        name,
        theme,
        eventDate,
        eventStartSubscriptionForSpeakers,
        eventEndSubscriptionForSpeakers,
        eventStartSubscriptionForListeners,
        eventEndSubscriptionForListeners,
        isOpen: isEventOpen,
      });

      toast({
        title: "Evento cadastrado com sucesso",
        description: "O evento foi cadastrado!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/allEvents");
    } catch (error) {
      toast({
        title: "Algum erro aconteceu",
        description: "Contate o Administrador do Sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const onEdit = async (data) => {
    setIsLoading(true);
    try {
      let logo;
      if (newImage !== event.image) {
        logo = `eventImage-${v4()}.jpeg`;
        uploadFile(newImage, logo);
      } else {
        logo = event.image;
      }
      await client.patch(`/events/${event.eventId}`, {
        image: logo,
        isOpen: isEventOpen,
        ...data,
      });
      toast({
        title: "Evento alterado com sucesso",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/allEvents");
    } catch (error) {
      console.log(error);
      toast({
        title: "Algum erro aconteceu",
        description: "Contate o Administrador do Sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const handleImage = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setNewImage(URL.createObjectURL(file));
    }
  };

  return (
    <form onSubmit={event ? handleSubmit(onEdit) : handleSubmit(onSubmit)}>
      <Flex flexDir="column" gap="5" my={5}>
        <Box
          border={"1px solid #ccc"}
          borderRadius={"8px"}
          p={[2, 5]}
          bgColor={"white"}
        >
          <Badge fontSize={"1rem"} colorScheme="whatsapp" mb={2}>
            Informações do Evento
          </Badge>
          <Flex flexDir={"column"} gap={3}>
            <Stack direction={["column", "row"]}>
              <FormLabel w={"100%"} m={0}>
                Nome do Evento
                <Input
                  required
                  type="text"
                  placeholder="Nome do evento"
                  {...register("name")}
                  defaultValue={event && event.name}
                />
              </FormLabel>
            </Stack>
            <Stack direction={["column", "row"]}>
              <FormLabel w={"100%"} m={0}>
                Tema do Evento
                <Input
                  required
                  type="text"
                  placeholder="Tema do evento"
                  {...register("theme")}
                  defaultValue={event && event.theme}
                />
              </FormLabel>
            </Stack>
            <Stack direction={["column", "row"]}>
              <FormLabel w={"100%"} m={0}>
                Início inscrições Palestrantes
                <Input
                  required
                  type="date"
                  placeholder=""
                  {...register("eventStartSubscriptionForSpeakers")}
                  defaultValue={
                    event && event.eventStartSubscriptionForSpeakers
                  }
                />
              </FormLabel>
              <FormLabel w={"100%"} m={0}>
                Término inscrições Palestrantes
                <Input
                  required
                  type="date"
                  placeholder=""
                  {...register("eventEndSubscriptionForSpeakers")}
                  defaultValue={event && event.eventEndSubscriptionForSpeakers}
                />
              </FormLabel>
            </Stack>
            <Stack direction={["column", "row"]}>
              <FormLabel w={"100%"} m={0}>
                Início inscrições Ouvintes
                <Input
                  required
                  type="date"
                  placeholder=""
                  {...register("eventStartSubscriptionForListeners")}
                  defaultValue={
                    event && event.eventStartSubscriptionForListeners
                  }
                />
              </FormLabel>

              <FormLabel w={"100%"} m={0}>
                Término inscrições Ouvintes
                <Input
                  required
                  type="date"
                  placeholder=""
                  {...register("eventEndSubscriptionForListeners")}
                  defaultValue={event && event.eventEndSubscriptionForListeners}
                />
              </FormLabel>
            </Stack>
            <Stack direction={["column", "row"]}>
              <FormLabel w={"100%"} m={0}>
                Data do Evento
                <Input
                  required
                  type="date"
                  placeholder=""
                  {...register("eventDate")}
                  defaultValue={event && event.eventDate}
                />
              </FormLabel>
              <FormLabel w={"100%"} m={0}>
                Evento aberto ou fechado?
                <Flex p={2} alignItems={"center"}>
                  <RadioGroup
                    onChange={setIsEventOpen}
                    defaultValue={isEventOpen}
                  >
                    <Stack direction="row">
                      <Radio value={"true"}>Aberto</Radio>
                      <Radio value={"false"}>Fechado</Radio>
                    </Stack>
                  </RadioGroup>
                </Flex>
              </FormLabel>
            </Stack>
          </Flex>
        </Box>

        <Box
          p={[2, 5]}
          bgColor={"white"}
          border={"1px solid #ccc"}
          borderRadius={"8px"}
        >
          <Badge fontSize={"1rem"} colorScheme="whatsapp" mb={2}>
            Logo do Evento
          </Badge>
          <Flex
            flexDir={["column", "row"]}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={5}
          >
            <Flex flexDir={["column", "row"]} w={["100%"]}>
              <div>
                <Input
                  type="file"
                  accept="image/*"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleImage}
                  ref={fileInput}
                />
                <label htmlFor="fileInput">
                  <Flex alignItems={"center"} gap={10}>
                    <FormLabel m={0}>Adicione a imagem do Evento</FormLabel>
                    <IconButton
                      colorScheme="whatsapp"
                      borderRadius={"100%"}
                      w={["70px", "85px"]}
                      h={["70px", "85px"]}
                      aria-label="Upload File"
                      icon={<MdAddAPhoto fontSize={"45px"} />}
                      onClick={() => fileInput.current.click()}
                    />
                  </Flex>
                </label>
              </div>
            </Flex>
            <Flex justifyContent={"center"} w={"100%"}>
              {newImage &&
                (event?.image ? (
                  <Image
                    objectFit={"cover"}
                    src={`https://${split[0]}.s3.amazonaws.com/${split[1]}/${event?.image}`}
                  />
                ) : (
                  <Image objectFit={"cover"} src={newImage} />
                ))}
            </Flex>
          </Flex>
        </Box>

        <Flex justifyContent={"center"}>
          <Button
            isLoading={isLoading}
            colorScheme="whatsapp"
            w={["100%", "40%"]}
            type="submit"
          >
            {event ? "Editar Evento" : "Cadastrar Evento"}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
export default EventForm;
