import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { addHours, format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaMicrophone } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { useAuth } from "../../context/AuthContext";

const EventItem = ({ event, onDelete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const split = process.env.REACT_APP_S3_EVENTSIMAGES.split("/");
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{event.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex w={"100%"} gap={3} flexDir={"column"}>
              <Text>{event.theme}</Text>
              <Image
                src={`https://${split[0]}.s3.amazonaws.com/${split[1]}/${event?.image}`}
                objectFit={"contain"}
                maxH={"300px"}
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Voltar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Tr>
        <Td>{event.name}</Td>
        <Td>
          {format(
            new Date(addHours(new Date(event?.eventDate), 3)),
            "dd 'de' MMMM 'de' yyyy",
            {
              locale: ptBR,
            }
          )}
        </Td>
        <Td>{event.speakers.length}</Td>
        <Td>{event.listeners.length}</Td>
        <Td>
          <Flex gap={5}>
            <Tooltip label="Detalhes do evento">
              <IconButton
                colorScheme="blue"
                aria-label="Detalhes"
                size="sm"
                onClick={onOpen}
                icon={<SearchIcon />}
              />
            </Tooltip>

            {auth?.user.type === process.env.REACT_APP_ADMINISTRATOR && (
              <>
                <Tooltip label="Adicionar conteúdo do evento">
                  <IconButton
                    colorScheme="teal"
                    aria-label="Adicionar Conteúdo"
                    size="sm"
                    onClick={() => {
                      navigate(`/addEventContent/${event.eventId}`);
                    }}
                    icon={<BsYoutube />}
                  />
                </Tooltip>
                <Tooltip label="Excluir evento">
                  <IconButton
                    colorScheme="red"
                    aria-label="Excluir"
                    size="sm"
                    isLoading={isLoading}
                    onClick={() => {
                      setIsLoading(true);
                      onDelete(event.eventId);
                      setIsLoading(false);
                    }}
                    icon={<DeleteIcon />}
                  />
                </Tooltip>
                <Tooltip label="Editar evento">
                  <IconButton
                    colorScheme="yellow"
                    aria-label="Editar"
                    size="sm"
                    isLoading={isLoading}
                    onClick={() => {
                      setIsLoading(true);
                      navigate(`/createEvent/${event.eventId}`);
                    }}
                    icon={<EditIcon />}
                  />
                </Tooltip>
              </>
            )}

            <Tooltip label="Ver inscritos do evento">
              <IconButton
                colorScheme="linkedin"
                aria-label="Editar"
                size="sm"
                isLoading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  navigate(`/eventInscriptions/${event.eventId}`);
                }}
                icon={<FaMicrophone />}
              />
            </Tooltip>
          </Flex>
        </Td>
      </Tr>
    </>
  );
};
export default EventItem;
