import {
  Box,
  Flex,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import SponsorCarousel from "../SponsorCarousel";
import { MdCheckCircle } from "react-icons/md";

const About = () => {
  return (
    <Flex flexDir={"column"}>
      <Heading
        fontFamily={"Afacad"}
        variant={"outline"}
        colorScheme="green"
        textDecor={"underline"}
        textDecorationColor={"green"}
        p={[2, 6]}
      >
        A Acaol e a Jornada Maçônica
      </Heading>
      <Flex
        alignItems={"center"}
        m={"auto"}
        p={5}
        mt={2}
        w={["100%", "90%", "85%", "95%"]}
        flexDir={["column", "row"]}
        gap={5}
        justifyContent={"space-between"}
      >
        <Flex
          w={["100%", "20%"]}
          justifyContent={"center"}
          gap={5}
          flexDir={"column"}
          alignSelf={"center"}
        >
          <SponsorCarousel type={"Secundário"} />
          <Flex flexDir={["column", "row"]} gap={3}>
            <Image
              w={["100%", "50%"]}
              src={`${process.env.PUBLIC_URL}/logoBig.png`}
            />
            <Image
              w={["100%", "50%"]}
              src={`${process.env.PUBLIC_URL}/jornadaazul.png`}
            />
          </Flex>
        </Flex>
        <Box w={["100%", "80%"]} alignSelf={"flex-start"}>
          <Text fontSize={"1.1rem"} fontFamily={"Afacad"}>
            <Text style={{ textIndent: "1em" }}>
              Em fevereiro de 1996, Irmãos de diversas Lojas da Zona Leste de
              São Paulo levantaram duas bandeiras de trabalho:
            </Text>
            <List spacing={3} mt={3} mb={3}>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="green.500" />A Social:
                Fundar uma Associação para atender 10 Lojas Maçônicas,
                construindo 2 Templos com estrutura suficiente para promover
                trabalhos de Assistência Social e Lazer na região.
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="green.500" />A Cultural:
                Organizar um movimento cultural maçônico para os Irmãos da
                região.
              </ListItem>
            </List>
            <Text style={{ textIndent: "1em" }} mb={3}>
              Em Janeiro de 1996 foram estabelecidos os fundamentos da Jornada
              Maçônica e sua 1ª Edição se realizou em setembro do mesmo ano, com
              sucesso e coroada como um dos Eventos Comemorativos dos 70 anos da
              Grande Loja Maçônica do Estado de São Paulo.
            </Text>
            <Text style={{ textIndent: "1em" }} mb={3}>
              Contudo, o evento que nasceu para atender as bandeiras social e
              cultural da região das Lojas da Zona Leste do município de São
              Paulo, rapidamente se projetou em magnitude estadual e nacional,
              passando a ser "Jornada Maçônica de São Paulo" e, atualmente,
              "Jornada Maçônica do Brasil".
            </Text>
            <Text style={{ textIndent: "1em" }} mb={3}>
              Este alcance foi possível com a participação de Maçons de várias
              partes do Brasil e do Exterior, com o modelo virtual implementado
              em sua XXV Edição em setembro de 2022, decorrente da condição
              imposta pela pandemia que o mundo enfrentou. Mérito e dedicação da
              equipe de Irmãos organizadores, que em março de 2022, diante da
              imponderabilidade do que ocorreria em curto e médio prazo, dos
              lockdown´s impostos para o enfrentamento ao COVID-19, ousaram
              responder "SIM, a edição do Jubileu de Prata da Jornada Maçônica
              do Brasil será realizada. Será conduzida em formato totalmente
              virtual e que buscará Irmãos Palestrantes e o público maçônico por
              todo o Brasil."
            </Text>
            <Text style={{ textIndent: "1em" }} mb={3}>
              Enfrentando as maiores dificuldades impostas naquele momento, a
              fé, a perseverança, a capacidade, o comprometimento e a
              determinação dos componentes da organização tornaram algo, até
              então inusitado, na XXV Jornada Maçônica do Brasil, sua 1ª Edição
              100% Digital.
            </Text>
            <Text style={{ textIndent: "1em" }} mb={3}>
              Seus objetivos precípuos, no entanto, continuam os mesmos: a
              interação de Maçons de diversas Lojas, Potências e Ritos e a
              disseminação, através de escritores, profissionais liberais e
              outros formadores de opinião, oriundos do meio maçônico, de
              relevantes assuntos internos e externos à Ordem.
            </Text>
            <Text style={{ textIndent: "1em" }} mb={3}>
              Suas metas passam a ser superadas ano a ano com a evolução destes
              assuntos e crescimento exponencial da cultura maçônica se
              propagando a todos os espaços do universo.
            </Text>
            <Text style={{ textIndent: "1em" }} mb={3}>
              Esse é o legado que a ACAOL - Associação Cultural e Assistencial
              Obreiros do Leste construiu e que vem expandindo, agora com uma
              integração em nível nacional e internacional, sempre com
              colaboração e participação de estimados e valorosos Irmãos das
              diversas Unidades da Federação.
            </Text>
            <Text
              fontSize={"1.2rem"}
              style={{ textIndent: "1em" }}
              mb={3}
              fontWeight={700}
            >
              Assim, some-se a nós nesta peleja e seja bem-vindo ao nosso
              portal!
            </Text>
          </Text>
        </Box>
      </Flex>
      <hr />
    </Flex>
  );
};
export default About;
