import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "./style.css";
import CarouselItem from "../CarouselItem";
import client from "../../utils/client";
import { useEffect, useState } from "react";
import { Skeleton } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const SponsorCarousel = ({ type }) => {
  const [sponsors, setSponsors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const request = async () => {
      const response = await client.get("/sponsors");
      if (response.data) {
        const data = response.data.sponsorItems;
        setSponsors(data.filter((sponsor) => sponsor.type === type));
      }
    };
    try {
      request().then(() => setIsLoading(false));
    } catch (error) {
      alert(error);
      setIsLoading(false);
    }
  }, [type]);

  return (
    <Skeleton isLoaded={!isLoading}>
      <Swiper
        modules={[Pagination, A11y, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoHeight={false}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: true,
          reverseDirection: false,
        }}
        className="swiper-slide-image"
      >
        {sponsors[0] &&
          sponsors.map((el) => (
            <SwiperSlide key={el.sponsorId} className="swiper-slide-image">
              <Link to={el.targetUrl} target="_blank">
                <CarouselItem
                  noBlackImage={true}
                  imageUrl={`${process.env.REACT_APP_S3_BASEURL}/sponsors_images/${el.images[0]}`}
                  imageFit={true}
                />
              </Link>
            </SwiperSlide>
          ))}
      </Swiper>
    </Skeleton>
  );
};
export default SponsorCarousel;
