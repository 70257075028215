import { Flex, Heading } from "@chakra-ui/react";
import Layout from "../components/Layout";

const NotAuthorized = () => {
  return (
    <Layout>
      <Flex justifyContent={"center"}>
        <Flex
          w={["90%", "90%", "90%", "90%"]}
          padding="6"
          boxShadow="lg"
          bg="white"
          mt={[8]}
          justifyContent={"center"}
        >
          <Heading
            fontFamily={"Afacad"}
            variant={"outline"}
            colorScheme="green"
            textDecor={"underline"}
            textDecorationColor={"#B50B16"}
            color={"#DC1522"}
          >
            VOCÊ NÃO TEM AUTORIZAÇÃO PARA VER ESTA PÁGINA!
          </Heading>
        </Flex>
      </Flex>
    </Layout>
  );
};
export default NotAuthorized;
