import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { resizeImage } from "../../utils/images";
import { myBucket } from "../../utils/bucket";
import { v4 } from "uuid";
import client from "../../utils/client";
import { useNavigate } from "react-router-dom";
import { MdAddAPhoto } from "react-icons/md";
import { useAuth } from "../../context/AuthContext";

const RegisterForm = ({ user }) => {
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const toast = useToast();
  const [isActiveMasonvalue, setIsActiveMasonValue] = useState(
    user?.isActiveMason ? user.isActiveMason : ""
  );
  const [symbolicGrade, setSymbolicGrade] = useState(
    user?.symbolicGrade ? user.symbolicGrade : ""
  );
  const [newImage, setNewImage] = useState(auth?.userPhoto || undefined);
  const [phoneNumber, setPhoneNumber] = useState(
    user?.phoneNumber ? user.phoneNumber : undefined
  );
  const [cpf, setCpf] = useState(user?.cpf ? user.cpf : undefined);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const fileInput = useRef(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formattedCep, setFormattedCep] = useState();

  const uploadFile = async (file, photoId) => {
    const response = await fetch(file);
    const blob = await response.blob();
    const fileObj = new File([blob], "image.jpg", { type: "image/jpeg" });
    const params = {
      ACL: "public-read",
      Body: fileObj,
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Key: photoId,
    };

    myBucket.putObject(params, (err, data) => {
      if (err) console.log(err);
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const photo = `userPhoto-${v4()}.jpeg`;
      uploadFile(newImage, photo);
      await client.post("/users", {
        photo,
        ...data,
        type: process.env.REACT_APP_MASON,
        phoneNumber,
        cpf,
      });
      toast({
        title: "Conta criada com sucesso",
        description: "Sua conta foi criada!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/");
    } catch (error) {
      toast({
        title: "Algum erro aconteceu",
        description: "Contate o Administrador do Sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const handleImage = async (e) => {
    const resizedImage = await resizeImage(e.target.files[0], 150, 150);
    setNewImage(resizedImage);
  };

  const handlePhoneNumberChange = (event) => {
    let value = event.target.value.replace(/\D/g, "");
    if (value.length <= 2) {
      value = value.replace(/^(\d{0,2})/, "($1");
    } else if (value.length <= 7) {
      value = value.replace(/^(\d{2})(\d{0,5})/, "($1) $2");
    } else {
      value = value.replace(/^(\d{2})(\d{5})(\d{0,4})/, "($1) $2-$3");
    }
    setPhoneNumber(value);
  };

  const handleCpf = (event) => {
    let value = event.target.value.replace(/\D/g, "");
    if (value.length <= 3) {
      value = value.replace(/^(\d{0,3})/, "$1");
    } else if (value.length <= 6) {
      value = value.replace(/^(\d{3})(\d{0,3})/, "$1.$2");
    } else if (value.length <= 9) {
      value = value.replace(/^(\d{3})(\d{3})(\d{0,3})/, "$1.$2.$3");
    } else if (value.length <= 11) {
      value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{0,2})/, "$1.$2.$3-$4");
    }
    setCpf(value);
  };

  const handleCepChange = (event) => {
    const { value } = event.target;

    const numericCep = value.replace(/\D/g, "");

    // Formata o CEP no padrão "00000-000"
    const formatted = numericCep.replace(/^(\d{5})(\d{3})/, "$1-$2");

    setFormattedCep(formatted);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await client.delete(`/users/${user.userId}`).then(() => {
        auth.logout(navigate("/"));
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onEdit = async (data) => {
    setIsLoading(true);
    try {
      let photo;
      if (newImage !== auth?.userPhoto) {
        photo = `userPhoto-${v4()}.jpeg`;
        uploadFile(newImage, photo);
      } else {
        photo = auth.user.photo;
      }

      await client.put(`/users/${user.userId}`, {
        photo,
        ...data,
        type: auth.user.type,
        phoneNumber,
        cpf,
      });
      toast({
        title: "Conta Alterada com sucesso",
        description: "Sua conta foi alterada!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast({
        title: "Algum erro aconteceu",
        description: "Contate o Administrador do Sistema",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const handleAuth = async (data) => {
    setLoading(true);
    const response = await client.get(`/users/check/${data.invite}`);
    if (!!response.data.exists) {
      setIsAuthorized(true);
      setLoading(false);
      reset();
    } else {
      toast({
        title: "Convite Inválido!",
        description: "Revise o código de convite",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return user || isAuthorized ? (
    <>
      <form onSubmit={user ? handleSubmit(onEdit) : handleSubmit(onSubmit)}>
        <Flex flexDir="column" gap="5" my={5}>
          {user && (
            <Flex
              flexDir={["column", "row"]}
              justifyContent={"center"}
              alignItems={"center"}
              gap={3}
            >
              <Text fontSize={["1rem", "1.3rem"]} fontWeight={700}>
                Seu código para convidar outros usuários é:{" "}
              </Text>
              <Badge
                fontSize={["1rem", "1.3rem"]}
                colorScheme="green"
                p={2}
                borderRadius={6}
                textTransform="none"
              >
                {user.userId.slice(-6)}
              </Badge>
            </Flex>
          )}
          <Text color={"#e53e41"} fontSize={"0.8rem"}>
            Campos marcados com * são obrigatórios
          </Text>

          <Box
            border={"1px solid #ccc"}
            borderRadius={"8px"}
            p={[2, 5]}
            bgColor={"white"}
          >
            <Badge fontSize={"1rem"} colorScheme="whatsapp" mb={2}>
              Informações pessoais
            </Badge>
            <Flex flexDir={"column"} gap={3}>
              <Stack direction={["column", "row"]}>
                <FormControl isRequired>
                  <FormLabel w={"100%"} m={0}>
                    Nome Completo
                  </FormLabel>
                  <Input
                    required
                    type="text"
                    placeholder="Fulano de Tal"
                    {...register("name")}
                    defaultValue={user && user.name}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel w={"100%"} m={0}>
                    Celular
                  </FormLabel>
                  <Input
                    maxLength={15}
                    required
                    type="tel"
                    placeholder="(XX) XXXXX-XXXX"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    defaultValue={user && user?.phoneNumber}
                  />
                </FormControl>
              </Stack>
              <Stack direction={["column", "row"]}>
                <FormControl isRequired>
                  <FormLabel w={"100%"} m={0}>
                    Cidade
                  </FormLabel>
                  <Input
                    required
                    type="text"
                    placeholder="Cidade"
                    {...register("city")}
                    defaultValue={user && user.city}
                  />
                </FormControl>
                <FormLabel w={"100%"} m={0}>
                  Endereço Completo
                  <Input
                    type="text"
                    placeholder="Endereço"
                    {...register("address")}
                    defaultValue={user && user?.address}
                  />
                </FormLabel>
                <FormLabel w={"100%"} m={0}>
                  CEP
                  <Input
                    type="text"
                    placeholder="Cep"
                    {...register("cep")}
                    value={formattedCep}
                    onChange={handleCepChange}
                    defaultValue={user && user?.cep}
                  />
                </FormLabel>
                <FormLabel w={"100%"} m={0}>
                  Estado
                  <Select id="state" name="state" {...register("state")}>
                    <option selected={user && user.state === "AC"} value="AC">
                      Acre
                    </option>
                    <option selected={user && user.state === "AL"} value="AL">
                      Alagoas
                    </option>
                    <option selected={user && user.state === "AP"} value="AP">
                      Amapá
                    </option>
                    <option selected={user && user.state === "AM"} value="AM">
                      Amazonas
                    </option>
                    <option selected={user && user.state === "BA"} value="BA">
                      Bahia
                    </option>
                    <option selected={user && user.state === "CE"} value="CE">
                      Ceará
                    </option>
                    <option selected={user && user.state === "DF"} value="DF">
                      Distrito Federal
                    </option>
                    <option selected={user && user.state === "ES"} value="ES">
                      Espírito Santo
                    </option>
                    <option selected={user && user.state === "GO"} value="GO">
                      Goiás
                    </option>
                    <option selected={user && user.state === "MA"} value="MA">
                      Maranhão
                    </option>
                    <option selected={user && user.state === "MT"} value="MT">
                      Mato Grosso
                    </option>
                    <option selected={user && user.state === "MS"} value="MS">
                      Mato Grosso do Sul
                    </option>
                    <option selected={user && user.state === "MG"} value="MG">
                      Minas Gerais
                    </option>
                    <option selected={user && user.state === "PA"} value="PA">
                      Pará
                    </option>
                    <option selected={user && user.state === "PB"} value="PB">
                      Paraíba
                    </option>
                    <option selected={user && user.state === "PR"} value="PR">
                      Paraná
                    </option>
                    <option selected={user && user.state === "PE"} value="PE">
                      Pernambuco
                    </option>
                    <option selected={user && user.state === "PI"} value="PI">
                      Piauí
                    </option>
                    <option selected={user && user.state === "RJ"} value="RJ">
                      Rio de Janeiro
                    </option>
                    <option selected={user && user.state === "RN"} value="RN">
                      Rio Grande do Norte
                    </option>
                    <option selected={user && user.state === "RS"} value="RS">
                      Rio Grande do Sul
                    </option>
                    <option selected={user && user.state === "RO"} value="RO">
                      Rondônia
                    </option>
                    <option selected={user && user.state === "RR"} value="RR">
                      Roraima
                    </option>
                    <option selected={user && user.state === "SC"} value="SC">
                      Santa Catarina
                    </option>
                    <option selected={user && user.state === "SP"} value="SP">
                      São Paulo
                    </option>
                    <option selected={user && user.state === "SE"} value="SE">
                      Sergipe
                    </option>
                    <option selected={user && user.state === "TO"} value="TO">
                      Tocantins
                    </option>
                    <option selected={user && user.state === "EX"} value="EX">
                      Estrangeiro
                    </option>
                  </Select>
                </FormLabel>
              </Stack>
              <Stack direction={["column", "row"]}>
                <FormLabel w={"100%"} m={0}>
                  Data de Nascimento
                  <Input
                    type="date"
                    placeholder="Aniversário"
                    {...register("bithdate")}
                    defaultValue={user && user?.bithdate}
                  />
                </FormLabel>
                <FormLabel w={"100%"} m={0}>
                  CPF
                  <Input
                    maxLength={14}
                    type="text"
                    placeholder="CPF"
                    value={cpf}
                    onChange={handleCpf}
                    defaultValue={user && user?.cpf}
                  />
                </FormLabel>
                <FormLabel w={"100%"} m={0}>
                  Trabalho/Ocupação
                  <Input
                    type="text"
                    placeholder="Ocupação"
                    {...register("job")}
                    defaultValue={user && user?.job}
                  />
                </FormLabel>
              </Stack>
            </Flex>
          </Box>
          <Box
            p={[2, 5]}
            bgColor={"white"}
            border={"1px solid #ccc"}
            borderRadius={"8px"}
          >
            <Badge fontSize={"1rem"} colorScheme="whatsapp" mb={2}>
              Informações de login
            </Badge>
            <Stack direction={["column", "row"]}>
              <FormControl isRequired>
                <FormLabel w={"100%"} m={0}>
                  E-mail
                </FormLabel>
                <Input
                  required
                  type="text"
                  placeholder="E-mail"
                  {...register("email")}
                  defaultValue={user && user?.email}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel w={"100%"} m={0}>
                  Senha
                </FormLabel>
                <Input
                  required
                  type="password"
                  placeholder="Senha"
                  {...register("password")}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel w={"100%"} m={0}>
                  Repita a senha
                </FormLabel>
                <Input
                  required
                  type="password"
                  placeholder="Repita a Senha"
                  {...register("password2", {
                    validate: (value) => {
                      if (value !== watch("password")) {
                        return "As senhas não correspondem";
                      }
                    },
                  })}
                />
                {errors.password2 && (
                  <Text color={"#86011F"}>{errors.password2.message}</Text>
                )}
              </FormControl>
            </Stack>
          </Box>
          <Box
            p={[2, 5]}
            bgColor={"white"}
            border={"1px solid #ccc"}
            borderRadius={"8px"}
          >
            <Badge fontSize={"1rem"} colorScheme="whatsapp" mb={2}>
              Informações Maçônicas
            </Badge>

            <Flex
              justifyContent={"space-between"}
              mb={[2, 5]}
              flexDir={["column", "row"]}
              gap={10}
            >
              <FormControl isRequired>
                <Flex alignItems={"center"}>
                  <FormLabel my={0}>Maçom Ativo?</FormLabel>
                  <RadioGroup
                    required
                    onChange={setIsActiveMasonValue}
                    value={isActiveMasonvalue}
                  >
                    <Stack direction={["column", "row"]}>
                      <Radio value={"true"} {...register("isActiveMason")}>
                        Ativo
                      </Radio>
                      <Radio value={"false"} {...register("isActiveMason")}>
                        Inativo
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Flex>
              </FormControl>
              <FormControl isRequired>
                <Flex alignItems={"center"}>
                  <FormLabel my={0}>Grau Simbólico</FormLabel>
                  <RadioGroup
                    required
                    onChange={setSymbolicGrade}
                    value={symbolicGrade}
                  >
                    <Stack
                      display={"flex"}
                      wrap={"wrap"}
                      direction={["column", "row"]}
                    >
                      <Radio value={"1"} {...register("symbolicGrade")}>
                        Aprendiz
                      </Radio>
                      <Radio value={"2"} {...register("symbolicGrade")}>
                        Companheiro
                      </Radio>
                      <Radio value={"3"} {...register("symbolicGrade")}>
                        Mestre
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Flex>
              </FormControl>
            </Flex>

            <Flex flexDir={"column"} gap={2}>
              <Stack direction="row">
                <FormLabel w={"100%"} m={0}>
                  Rito
                  <Select required {...register("rite")}>
                    <option value="" disabled selected={!user ? true : false}>
                      Rito
                    </option>
                    <option
                      selected={user && user?.rite === "adonhiramita"}
                      value="adonhiramita"
                    >
                      Adonhiramita
                    </option>
                    <option
                      selected={user && user?.rite === "reaa"}
                      value="reaa"
                    >
                      REAA
                    </option>
                    <option
                      selected={user && user?.rite === "york"}
                      value="york"
                    >
                      York (Americano)
                    </option>
                    <option
                      selected={user && user?.rite === "york-GOB"}
                      value="york-GOB"
                    >
                      York (GOB)
                    </option>
                    <option
                      selected={user && user?.rite === "schroder"}
                      value="schroder"
                    >
                      Schröder
                    </option>
                    <option
                      selected={user && user?.rite === "brasileiro"}
                      value="brasileiro"
                    >
                      Brasileiro
                    </option>
                    <option
                      selected={user && user?.rite === "moderno"}
                      value="moderno"
                    >
                      Moderno
                    </option>
                    <option selected={user && user?.rite === "rer"} value="rer">
                      Escocês Retificado
                    </option>
                    <option
                      selected={user && user?.rite === "emulacao"}
                      value="emulacao"
                    >
                      Emulação
                    </option>
                  </Select>
                </FormLabel>

                <FormControl isRequired>
                  <FormLabel w={"100%"} m={0}>
                    Obediência
                  </FormLabel>
                  <Select required {...register("obedience")}>
                    <option value="" disabled selected={!user ? true : false}>
                      Obediência
                    </option>
                    <option
                      selected={user && user?.obedience === "GLEAC"}
                      value="GLEAC"
                    >
                      GLEAC
                    </option>
                    <option
                      selected={user && user?.obedience === "GLEMA"}
                      value="GLEMA"
                    >
                      GLEMA
                    </option>
                    <option
                      selected={user && user?.obedience === "GLEMPB"}
                      value="GLEMPB"
                    >
                      GLEMPB
                    </option>
                    <option
                      selected={user && user?.obedience === "GLEMS"}
                      value="GLEMS"
                    >
                      GLEMS
                    </option>
                    <option
                      selected={user && user?.obedience === "GLEMT"}
                      value="GLEMT"
                    >
                      GLEMT
                    </option>
                    <option
                      selected={user && user?.obedience === "GLEMTO"}
                      value="GLEMTO"
                    >
                      GLEMTO
                    </option>
                    <option
                      selected={user && user?.obedience === "GLEPA"}
                      value="GLEPA"
                    >
                      GLEPA
                    </option>
                    <option
                      selected={user && user?.obedience === "GLERN"}
                      value="GLERN"
                    >
                      GLERN
                    </option>
                    <option
                      selected={user && user?.obedience === "GLESC"}
                      value="GLESC"
                    >
                      GLESC
                    </option>
                    <option
                      selected={user && user?.obedience === "GLESP"}
                      value="GLESP"
                    >
                      GLESP
                    </option>
                    <option
                      selected={user && user?.obedience === "GLMDF"}
                      value="GLMDF"
                    >
                      GLMDF
                    </option>
                    <option
                      selected={user && user?.obedience === "GLMEBA"}
                      value="GLMEBA"
                    >
                      GLMEBA
                    </option>
                    <option
                      selected={user && user?.obedience === "GLMECE"}
                      value="GLMECE"
                    >
                      GLMECE
                    </option>
                    <option
                      selected={user && user?.obedience === "GLMEES"}
                      value="GLMEES"
                    >
                      GLMEES
                    </option>
                    <option
                      selected={user && user?.obedience === "GLMEGO"}
                      value="GLMEGO"
                    >
                      GLMEGO
                    </option>
                    <option
                      selected={user && user?.obedience === "GLMERJ"}
                      value="GLMERJ"
                    >
                      GLMERJ
                    </option>
                    <option
                      selected={user && user?.obedience === "GLMES"}
                      value="GLMES"
                    >
                      GLMES
                    </option>
                    <option
                      selected={user && user?.obedience === "GLMMG"}
                      value="GLMMG"
                    >
                      GLMMG
                    </option>
                    <option
                      selected={user && user?.obedience === "GLMPI"}
                      value="GLMPI"
                    >
                      GLMPI
                    </option>
                    <option
                      selected={user && user?.obedience === "GLMRS"}
                      value="GLMRS"
                    >
                      GLMRS
                    </option>
                    <option
                      selected={user && user?.obedience === "GLOMAN"}
                      value="GLOMAN"
                    >
                      GLOMAN
                    </option>
                    <option
                      selected={user && user?.obedience === "GLOMAP"}
                      value="GLOMAP"
                    >
                      GLOMAP
                    </option>
                    <option
                      selected={user && user?.obedience === "GLOMARON"}
                      value="GLOMARON"
                    >
                      GLOMARON
                    </option>
                    <option
                      selected={user && user?.obedience === "GLOMEAL"}
                      value="GLOMEAL"
                    >
                      GLOMEAL
                    </option>
                    <option
                      selected={user && user?.obedience === "GLOMERR"}
                      value="GLOMERR"
                    >
                      GLOMERR
                    </option>
                    <option
                      selected={user && user?.obedience === "GLPR"}
                      value="GLPR"
                    >
                      GLPR
                    </option>
                    <option
                      selected={user && user?.obedience === "GOAM"}
                      value="GOAM"
                    >
                      GOAM
                    </option>
                    <option
                      selected={user && user?.obedience === "GOBA"}
                      value="GOBA"
                    >
                      GOBA
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-AC"}
                      value="GOB-AC"
                    >
                      GOB-AC
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-AL"}
                      value="GOB-AL"
                    >
                      GOB-AL
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-AM"}
                      value="GOB-AM"
                    >
                      GOB-AM
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-AP"}
                      value="GOB-AP"
                    >
                      GOB-AP
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-BA"}
                      value="GOB-BA"
                    >
                      GOB-BA
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-Brasília"}
                      value="GOB-Brasília"
                    >
                      GOB-Brasília
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-CE"}
                      value="GOB-CE"
                    >
                      GOB-CE
                    </option>
                    <option
                      selected={user && user?.obedience === "GODF"}
                      value="GODF"
                    >
                      GODF
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-DF"}
                      value="GOB-DF"
                    >
                      GOB-DF
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-ES"}
                      value="GOB-ES"
                    >
                      GOB-ES
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-GO"}
                      value="GOB-GO"
                    >
                      GOB-GO
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-MA"}
                      value="GOB-MA"
                    >
                      GOB-MA
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-MG"}
                      value="GOB-MG"
                    >
                      GOB-MG
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-MS"}
                      value="GOB-MS"
                    >
                      GOB-MS
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-MT"}
                      value="GOB-MT"
                    >
                      GOB-MT
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-PA"}
                      value="GOB-PA"
                    >
                      GOB-PA
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-PB"}
                      value="GOB-PB"
                    >
                      GOB-PB
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-PE"}
                      value="GOB-PE"
                    >
                      GOB-PE
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-PI"}
                      value="GOB-PI"
                    >
                      GOB-PI
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-PR"}
                      value="GOB-PR"
                    >
                      GOB-PR
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-RJ"}
                      value="GOB-RJ"
                    >
                      GOB-RJ
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-RN"}
                      value="GOB-RN"
                    >
                      GOB-RN
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-RO"}
                      value="GOB-RO"
                    >
                      GOB-RO
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-RR"}
                      value="GOB-RR"
                    >
                      GOB-RR
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-RS"}
                      value="GOB-RS"
                    >
                      GOB-RS
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-SC"}
                      value="GOB-SC"
                    >
                      GOB-SC
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-SE"}
                      value="GOB-SE"
                    >
                      GOB-SE
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-SP"}
                      value="GOB-SP"
                    >
                      GOB-SP
                    </option>
                    <option
                      selected={user && user?.obedience === "GOB-TO"}
                      value="GOB-TO"
                    >
                      GOB-TO
                    </option>
                    <option
                      selected={user && user?.obedience === "GOCE"}
                      value="GOCE"
                    >
                      GOCE
                    </option>
                    <option
                      selected={user && user?.obedience === "GOEMT"}
                      value="GOEMT"
                    >
                      GOEMT
                    </option>
                    <option
                      selected={user && user?.obedience === "GOIERN"}
                      value="GOIERN"
                    >
                      GOIERN
                    </option>
                    <option
                      selected={user && user?.obedience === "GOIPE"}
                      value="GOIPE"
                    >
                      GOIPE
                    </option>
                    <option
                      selected={user && user?.obedience === "GOIRJ"}
                      value="GOIRJ"
                    >
                      GOIRJ
                    </option>
                    <option
                      selected={user && user?.obedience === "GOMG"}
                      value="GOMG"
                    >
                      GOMG
                    </option>
                    <option
                      selected={user && user?.obedience === "GOMS"}
                      value="GOMS"
                    >
                      GOMS
                    </option>
                    <option
                      selected={user && user?.obedience === "GOP"}
                      value="GOP"
                    >
                      GOP
                    </option>
                    <option
                      selected={user && user?.obedience === "GOPB"}
                      value="GOPB"
                    >
                      GOPB
                    </option>
                    <option
                      selected={user && user?.obedience === "GOPI"}
                      value="GOPI"
                    >
                      GOPI
                    </option>
                    <option
                      selected={user && user?.obedience === "GOPR"}
                      value="GOPR"
                    >
                      GOPR
                    </option>
                    <option
                      selected={user && user?.obedience === "GORS"}
                      value="GORS"
                    >
                      GORS
                    </option>
                    <option
                      selected={user && user?.obedience === "GOSC"}
                      value="GOSC"
                    >
                      GOSC
                    </option>
                    <option
                      selected={user && user?.obedience === "GOSP"}
                      value="GOSP"
                    >
                      GOSP
                    </option>
                    <option
                      selected={user && user?.obedience === "GLMPE"}
                      value="GLMPE"
                    >
                      GLMPE
                    </option>
                    <option
                      selected={user && user?.obedience === "GLPeru"}
                      value="GLPeru"
                    >
                      Gran Logia del Perú
                    </option>
                    <option
                      selected={user && user?.obedience === "GLLP/GLRP"}
                      value="GLLP/GLRP"
                    >
                      GLLP/GLRP (Portugal)
                    </option>
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction={["column", "row"]}>
                <FormControl isRequired>
                  <FormLabel w={"100%"} m={0}>
                    Loja
                  </FormLabel>
                  <Input
                    required
                    type="text"
                    placeholder="Loja"
                    {...register("lodge")}
                    defaultValue={user && user?.lodge}
                  />
                </FormControl>
                <FormLabel w={"100%"} m={0}>
                  CIM ou similar
                  <Input
                    type="number"
                    placeholder="CIM"
                    {...register("cim")}
                    defaultValue={user && user?.cim}
                  />
                </FormLabel>
              </Stack>
            </Flex>
          </Box>
          <Box
            p={[2, 5]}
            bgColor={"white"}
            border={"1px solid #ccc"}
            borderRadius={"8px"}
          >
            <Badge fontSize={"1rem"} colorScheme="whatsapp" mb={2}>
              Foto de perfil
            </Badge>
            <Flex
              flexDir={["column", "row"]}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={5}
            >
              <Flex flexDir={["column", "row"]} w={["100%"]}>
                <div>
                  <Input
                    type="file"
                    accept="image/*"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleImage}
                    ref={fileInput}
                  />
                  <label htmlFor="fileInput">
                    <Flex alignItems={"center"} gap={10}>
                      <FormLabel m={0}>
                        {!user
                          ? "Adicione sua foto de Perfil"
                          : "Altere sua foto de perfil"}
                      </FormLabel>
                      <IconButton
                        colorScheme="whatsapp"
                        borderRadius={"100%"}
                        w={["70px", "85px"]}
                        h={["70px", "85px"]}
                        aria-label="Upload File"
                        icon={<MdAddAPhoto fontSize={"45px"} />}
                        onClick={() => fileInput.current.click()}
                      />
                    </Flex>
                  </label>
                </div>
              </Flex>
              <Flex justifyContent={"center"} w={"100%"}>
                {newImage && (
                  <Image
                    borderRadius={"100%"}
                    w={["120px", "150px"]}
                    h={["120px", "150px"]}
                    objectFit={"cover"}
                    src={newImage}
                  />
                )}
              </Flex>
            </Flex>
          </Box>
          <Flex justifyContent={"center"} gap={5}>
            <Button
              isLoading={isLoading}
              colorScheme="whatsapp"
              w={["100%", "40%"]}
              type="submit"
            >
              {!user ? "Cadastrar-se" : "Alterar Perfil"}
            </Button>
            {user && (
              <Button
                isLoading={isLoading}
                onClick={onOpen}
                colorScheme="red"
                w={["100%", "40%"]}
              >
                Excluir usuário
              </Button>
            )}
          </Flex>
        </Flex>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmar Exclusão</ModalHeader>
            <ModalCloseButton />
            <ModalBody>Deseja realmente apagar a sua conta?</ModalBody>

            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={handleDelete}>
                Confirmar exclusão
              </Button>
              <Button onClick={onClose} variant="ghost">
                Voltar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </form>
    </>
  ) : (
    <form key={"invite"} onSubmit={!isAuthorized && handleSubmit(handleAuth)}>
      <Flex flexDir="column" gap="5" my={5}>
        <Box
          border={"1px solid #ccc"}
          borderRadius={"8px"}
          p={[2, 5]}
          bgColor={"white"}
        >
          <Badge fontSize={"1rem"} colorScheme="whatsapp" mb={2}>
            Código de convite
          </Badge>
          <Flex
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={3}
          >
            <Stack direction={["column", "row"]}>
              <FormLabel w={"100%"} m={0}>
                Insira seu código de convite
                <Input
                  mt={4}
                  p={5}
                  required
                  type="text"
                  {...register("invite")}
                />
              </FormLabel>
            </Stack>
            <Button isLoading={loading} colorScheme="green" type="submit">
              Verificar
            </Button>
          </Flex>
        </Box>
      </Flex>
    </form>
  );
};

export default RegisterForm;
