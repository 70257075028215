import { Flex, Heading } from "@chakra-ui/react";
import HelpCard from "../HelpCard";

const Help = () => {
  return (
    <Flex flexDir={"column"}>
      <Heading
        fontFamily={"Afacad"}
        variant={"outline"}
        colorScheme="green"
        textDecor={"underline"}
        textDecorationColor={"green"}
        p={[2, 6]}
      >
        Como Ajudar?
      </Heading>

      <Flex flexDir={["column", "row"]}>
        <HelpCard
          title={"Patrocínio"}
          text={
            "Junte sua marca a este que, em sua modalidade, é o maior evento maçônico do país, com público participante de todos os Estados da Federação. Se seu produto pretende atingir faixas etárias > de 40 anos, num perfil socioeconômico 'C', 'B' e 'A', composto de profissionais liberais, empresários e funcionários de nível acima de supervisão, de nível superior e atuantes em suas comunidades, a Jornada Maçônica do Brasil é o evento adequado para seu planejamento de Marketing. Entre em contato conosco, para conhecer os planos de patrocínio e vincule seu produto/serviços, de forma perene, à XXIX Edição da Jornada Maçônica do Brasil."
          }
          image={`${process.env.PUBLIC_URL}/patroc.png`}
        />
        <HelpCard
          title={"Trabalhe Junto"}
          text={
            "Se você desejar atuar na Comissão Organizadora da Jornada ou como representante da Jornada Maçônica do Brasil em seu município, entre em contato conosco."
          }
          image={`${process.env.PUBLIC_URL}/trabjunt.png`}
        />
        <HelpCard
          title={"Contribuições"}
          text={
            "Além do trabalho da Comissão Organizadora, cujos integrantes investem parte de seu tempo para a realização do Evento, a ACAOL, mantenedora do Evento, não se constituindo numa sociedade com fins lucrativos, para entregar uma Jornada Maçônica do Brasil com qualidade, são necessários investimentos, que buscamos captar com Patrocinadores e Apoiadores. No entanto, você, meu Irmão, que já participou ou participará da próxima Jornada Maçônica e identificar nela algo que lhe proporcione valor intrínseco, como acesso à informações de qualidade, se desejar contribuir com qualquer valor, esse ato será muito bem vindo para que nosso projeto venha a apresentar resultados cada vez melhores para os Irmãos. Segue nossa chave de PIX - CNPJ 02542928000123"
          }
          image={`${process.env.PUBLIC_URL}/donate.png`}
        />
      </Flex>
      <hr />
    </Flex>
  );
};
export default Help;
