import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "./style.css";
import CarouselItem from "../CarouselItem";
import client from "../../utils/client";
import { useEffect, useState } from "react";
import { Skeleton } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const NewsCarousel = () => {
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const request = async () => {
      const response = await client.get("/news/lastNews");
      response.data && setNews(response.data);
    };
    try {
      request().then(() => setIsLoading(false));
    } catch (error) {
      alert(error);
      setIsLoading(false);
    }
  }, []);

  return (
    <Skeleton isLoaded={!isLoading}>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        autoHeight={false}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
          reverseDirection: false,
        }}
        className="swiper-slide-image"
      >
        {news[0] &&
          news.map((el) => (
            <SwiperSlide key={el.newsId} className="swiper-slide-image">
              <Link to={`/news/${el.newsId}`}>
                <CarouselItem
                  title={el.title}
                  text={el.text}
                  imageUrl={`${process.env.REACT_APP_S3_BASEURL}/news_images/${el.images[0]}`}
                />
              </Link>
            </SwiperSlide>
          ))}
      </Swiper>
    </Skeleton>
  );
};
export default NewsCarousel;
