import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const VideoCard = ({ video }) => {
  return (
    <Card maxW="xs" boxShadow="xl">
      <CardBody>
        <Link to={`./${video.videoId}`}>
          <Image
            src={`${process.env.REACT_APP_S3_BASEURL}/videos_images/${video.image}`}
            borderRadius="lg"
          />
        </Link>
        <Stack mt="6" spacing="3">
          <Heading size="md">{video.name}</Heading>
          <Text fontStyle={"italic"}>
            {video.authorName} - {video.eventName}
          </Text>
          <Flex gap={2} wrap={"wrap"}>
            {video?.tags[0] &&
              video.tags.map((tag) => <Badge colorScheme="green">{tag}</Badge>)}
          </Flex>
        </Stack>
      </CardBody>
      <Divider />
      <CardFooter>
        <Flex w={"100%"} justifyContent={"center"}>
          <Link to={`./${video.videoId}`}>
            <Button variant="solid" colorScheme="blue">
              Assistir
            </Button>
          </Link>
        </Flex>
      </CardFooter>
    </Card>
  );
};

export default VideoCard;
