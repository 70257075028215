import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { addHours, format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Link } from "react-router-dom";

const EventCard = ({ event }) => {
  return (
    <Card w="md" boxShadow="xl">
      <CardBody>
        <Flex w={"100%"} justifyContent={"center"}>
          <Link to={`./${event.eventId}`}>
            <Image
              src={`${process.env.REACT_APP_S3_BASEURL}/events_images/${event.image}`}
              borderRadius="lg"
              maxH={"250px"}
            />
          </Link>
        </Flex>
        <Stack mt="6" spacing="3">
          <Heading size="md">{event.name}</Heading>
          <Text fontStyle={"italic"}>Tema: {event.theme}</Text>
          <Text fontStyle={"italic"}>
            Data do Evento:{" "}
            {format(
              new Date(addHours(new Date(event?.eventDate), 3)),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: ptBR,
              }
            )}
          </Text>

          {
            <Flex gap={2} wrap={"wrap"}>
              {new Date(event.eventStartSubscriptionForSpeakers) <=
                new Date() &&
                new Date(
                  new Date(event.eventEndSubscriptionForSpeakers).setHours(
                    new Date(event.eventEndSubscriptionForSpeakers).getHours() +
                      27
                  )
                ) >= new Date() && (
                  <Badge colorScheme={"green"}>
                    Inscrições abertas para palestrantes
                  </Badge>
                )}
              {new Date(event.eventStartSubscriptionForListeners) <=
                new Date() &&
                new Date(
                  new Date(event.eventEndSubscriptionForListeners).setHours(
                    new Date(
                      event.eventEndSubscriptionForListeners
                    ).getHours() + 27
                  )
                ) >= new Date() && (
                  <Badge colorScheme={"green"}>
                    Inscrições abertas para ouvintes
                  </Badge>
                )}
            </Flex>
          }
        </Stack>
      </CardBody>
      <Divider />
      <CardFooter>
        <Flex w={"100%"} justifyContent={"center"}>
          <Link to={`./${event.eventId}`}>
            <Button variant="solid" colorScheme="blue">
              Mais Informações
            </Button>
          </Link>
        </Flex>
      </CardFooter>
    </Card>
  );
};

export default EventCard;
