import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { useAuth } from "../context/AuthContext";
import client from "../utils/client";
import { Box, Flex, Heading, Skeleton } from "@chakra-ui/react";
import RegisterForm from "../components/RegisterForm";

const Profile = () => {
  const auth = useAuth();
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const request = async () => {
      const response = await client.get(`/users/${auth.user.id}`);
      response.data && setUser(response.data);
    };

    try {
      request().then(() => setIsLoading(false));
    } catch (error) {
      alert(error);
      setIsLoading(false);
    }
  }, [auth.user.id]);

  return (
    <Layout>
      <Flex justifyContent={"center"}>
        <Box
          w={["100%", "80%", "80%", "70%"]}
          p={[2, 6]}
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Skeleton isLoaded={!isLoading}>
            <Heading
              fontFamily={"Afacad"}
              variant={"outline"}
              colorScheme="green"
              textDecor={"underline"}
              textDecorationColor={"green"}
            >
              Editar Perfil
            </Heading>
            <Flex alignItems={"center"} flexDir={"column"}>
              <Box w={"100%"}>
                <RegisterForm user={user && user} />
              </Box>
            </Flex>
          </Skeleton>
        </Box>
      </Flex>
    </Layout>
  );
};

export default Profile;
