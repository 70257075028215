import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../components/Layout";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import client from "../utils/client";
import { useAuth } from "../context/AuthContext";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import moment from "moment";

const EventDetails = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState();
  const auth = useAuth();
  const [value, setValue] = useState("on-line");

  const [loading, setLoading] = useState(false);
  const [gradeRestriction, setGradeRestriction] = useState("1");
  const toast = useToast();
  const navigate = useNavigate();

  const {
    isOpen: isListenerOpen,
    onOpen: onListenerOpen,
    onClose: onListenerClose,
  } = useDisclosure();
  const {
    isOpen: isSpeakerOpen,
    onOpen: onSpeakerOpen,
    onClose: onSpeakerClose,
  } = useDisclosure();

  useEffect(() => {
    const request = async () => {
      const response = await client.get(`/events/${eventId}`);
      response.data && setEvent(response.data);
    };
    request();
  }, [eventId]);

  const handleListenerSubscription = async (event) => {
    event.preventDefault();
    setLoading(true);
    const listener = {
      listenerId: auth.user?.id,
      listenerName: auth.user.name,
      type: value,
    };
    await client
      .patch(`/events/addListener/${eventId}`, { listener })
      .then(() => {
        setLoading(false);
        onListenerClose();
        toast({
          title: "Inscrição confirmada!",
          description: "Agora é aguardar a data do evento!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        navigate("/eventos");
      });
  };

  const handleSpeakerSubscription = async (event) => {
    event.preventDefault();
    setLoading(true);
    const speaker = {
      gradeRestriction,
      lectureName: event.target.lectureName.value,
      lectureDescription: event.target.lectureDescription.value,
      speakerId: auth.user?.id,
      speakerName: auth.user.name,
    };
    await client
      .patch(`/events/addSpeaker/${eventId}`, { speaker })
      .then(() => {
        setLoading(false);
        onSpeakerClose();
        toast({
          title: "Inscrição confirmada!",
          description: "Aguarde a comissão cultural entrar em contato!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        navigate("/eventos");
      });
  };

  const calculateTimeLeft = () => {
    const futureDate = new Date(event?.eventDate);
    futureDate.setHours(futureDate.getHours() + 3);
    const difference = moment(new Date(futureDate)).diff(moment());
    const duration = moment.duration(difference);
    return {
      years: duration.years(),
      months: duration.months(),
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <>
      {!!event?.isOpen && !!!auth?.user && navigate("/login")}
      <Modal onClose={onListenerClose} size={"xl"} isOpen={isListenerOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmação de inscrição - Ouvinte</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleListenerSubscription}>
            <ModalBody>
              <FormControl isRequired>
                <FormLabel>Tipo de inscrição:</FormLabel>
                <RadioGroup onChange={setValue} value={value} required>
                  <Stack direction="row" gap={3}>
                    <Radio value="on-line">On-Line</Radio>
                    <Radio value="presencial">Presencial</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Flex gap={3}>
                <Button
                  isLoading={loading}
                  colorScheme="blue"
                  onClick={onListenerClose}
                >
                  Voltar
                </Button>
                <Button type="submit" isLoading={loading} colorScheme="green">
                  Inscrever-se
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal onClose={onSpeakerClose} size={"xl"} isOpen={isSpeakerOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmação de inscrição - Palestrante</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSpeakerSubscription}>
            <ModalBody>
              <Flex flexDir={"column"} gap={4}>
                <FormControl isRequired>
                  <FormLabel>Nome da Palestra:</FormLabel>
                  <Input type="text" name="lectureName" />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Breve descrição da Palestra:</FormLabel>
                  <Textarea name="lectureDescription" />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Restrição:</FormLabel>
                  <RadioGroup
                    onChange={setGradeRestriction}
                    value={gradeRestriction}
                    required
                  >
                    <Stack direction="row" gap={3}>
                      <Radio value="1">Sem Restrição</Radio>
                      <Radio value="2">Companheiros</Radio>
                      <Radio value="3">Mestres</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Flex gap={3}>
                <Button
                  isLoading={loading}
                  colorScheme="blue"
                  onClick={onSpeakerClose}
                >
                  Voltar
                </Button>
                <Button type="submit" isLoading={loading} colorScheme="green">
                  Inscrever-se
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Layout>
        <Flex alignItems={"center"} flexDir={"column"}>
          <Box
            w={["90%", "90%", "90%", "90%"]}
            padding="6"
            boxShadow="lg"
            bg="white"
            mt={[8]}
          >
            <Skeleton isLoaded={event}>
              {event && (
                <Flex
                  flexDir={["column", "row"]}
                  alignItems={"center"}
                  w={"100%"}
                  gap={[5, 5]}
                  p={5}
                >
                  <Flex
                    flexDir={"column"}
                    gap={5}
                    w={["100%", "50%"]}
                    alignItems={"center"}
                  >
                    <Heading
                      fontFamily={"Afacad"}
                      variant={"outline"}
                      colorScheme="green"
                      textDecor={"underline"}
                      textDecorationColor={"green"}
                    >
                      {event?.name}
                    </Heading>
                    <Text fontSize={"1.4rem"} fontStyle={"italic"}>
                      {event?.theme}
                    </Text>
                    <Text fontSize={"1.2rem"}>
                      Data do evento:{" "}
                      {format(
                        new Date(
                          new Date(event.eventDate).setHours(
                            new Date(event.eventDate).getHours() + 3
                          )
                        ),
                        "dd 'de' MMMM 'de' yyyy",
                        { locale: ptBR }
                      )}
                    </Text>

                    {event.speakers.find(
                      (el) => el.speakerId === auth.user?.id
                    ) ? (
                      <Button isDisabled colorScheme={"green"}>
                        Palestra Inscrita
                      </Button>
                    ) : (
                      new Date(event.eventStartSubscriptionForSpeakers) <=
                        new Date() &&
                      new Date(
                        new Date(
                          event.eventEndSubscriptionForSpeakers
                        ).setHours(
                          new Date(
                            event.eventEndSubscriptionForSpeakers
                          ).getHours() + 27
                        )
                      ) >= new Date() && (
                        <Button onClick={onSpeakerOpen} colorScheme={"green"}>
                          Inscrições abertas para palestrantes
                        </Button>
                      )
                    )}

                    {event.listeners.find(
                      (el) => el.listenerId === auth.user?.id
                    ) ? (
                      <Button isDisabled colorScheme={"green"}>
                        Inscrito como ouvinte
                      </Button>
                    ) : (
                      new Date(event.eventStartSubscriptionForListeners) <=
                        new Date() &&
                      new Date(
                        new Date(
                          event.eventEndSubscriptionForListeners
                        ).setHours(
                          new Date(
                            event.eventEndSubscriptionForListeners
                          ).getHours() + 27
                        )
                      ) >= new Date() && (
                        <Button onClick={onListenerOpen} colorScheme={"green"}>
                          Inscrições abertas para ouvintes
                        </Button>
                      )
                    )}

                    {event.listeners.find(
                      (el) => el.listenerId === auth.user?.id
                    ) ? (
                      new Date(event?.eventDate) < new Date() ? (
                        <Link to={`/evento/${event.eventId}`}>
                          <Button colorScheme="blue">Acesse o Evento!</Button>
                        </Link>
                      ) : (
                        !isNaN(timeLeft.seconds) && (
                          <Flex
                            w={"70%"}
                            p={3}
                            border={"1px solid #ccc"}
                            borderRadius={8}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDir={["column", "row"]}
                          >
                            Faltam:
                            {timeLeft.years > 0 && (
                              <Flex
                                flexDir={"column"}
                                w={"20%"}
                                alignItems={"center"}
                                borderRadius={8}
                              >
                                <Text maxW="100%" fontWeight={"700"}>
                                  {timeLeft.years}
                                </Text>
                                <Text fontSize={"0.75rem"}>anos</Text>
                              </Flex>
                            )}
                            {(timeLeft.years !== 0 || timeLeft.months > 0) && (
                              <Flex
                                flexDir={"column"}
                                w={"20%"}
                                alignItems={"center"}
                              >
                                <Text maxW="100%" fontWeight={"700"}>
                                  {timeLeft.months}
                                </Text>
                                <Text fontSize={"0.75rem"}>meses</Text>
                              </Flex>
                            )}
                            {(timeLeft.months !== 0 || timeLeft.days > 0) && (
                              <Flex
                                flexDir={"column"}
                                w={"20%"}
                                alignItems={"center"}
                              >
                                <Text maxW="100%" fontWeight={"700"}>
                                  {timeLeft.days}
                                </Text>
                                <Text fontSize={"0.75rem"}>dias</Text>
                              </Flex>
                            )}
                            {(timeLeft.days !== 0 || timeLeft.hours > 0) && (
                              <Flex
                                flexDir={"column"}
                                w={"20%"}
                                alignItems={"center"}
                              >
                                <Text maxW="100%" fontWeight={"700"}>
                                  {timeLeft.hours}
                                </Text>
                                <Text fontSize={"0.75rem"}>horas</Text>
                              </Flex>
                            )}
                            {(timeLeft.hours !== 0 || timeLeft.minutes > 0) && (
                              <Flex
                                flexDir={"column"}
                                w={"20%"}
                                alignItems={"center"}
                              >
                                <Text maxW="100%" fontWeight={"700"}>
                                  {timeLeft.minutes}
                                </Text>
                                <Text fontSize={"0.75rem"}>minutos</Text>
                              </Flex>
                            )}
                            {(timeLeft.minutes !== 0 ||
                              timeLeft.seconds > 0) && (
                              <Flex
                                flexDir={"column"}
                                w={"20%"}
                                alignItems={"center"}
                              >
                                <Text maxW="100%" fontWeight={"700"}>
                                  {timeLeft.seconds}
                                </Text>
                                <Text fontSize={"0.75rem"}>segundos</Text>
                              </Flex>
                            )}
                          </Flex>
                        )
                      )
                    ) : (
                      new Date(event?.eventDate) < new Date() && (
                        <Text>
                          Acesse o conteúdo deste evento na aba ACAOL-Flix
                        </Text>
                      )
                    )}

                    <Link to={"/eventos"}>
                      <Button colorScheme="blue">Voltar</Button>
                    </Link>
                  </Flex>
                  <Flex w={["100%", "50%"]}>
                    <Image
                      src={`${process.env.REACT_APP_S3_BASEURL}/events_images/${event.image}`}
                    />
                  </Flex>
                </Flex>
              )}
            </Skeleton>
          </Box>
        </Flex>
      </Layout>
    </>
  );
};

export default EventDetails;
