import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import client from "../utils/client";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { Link, useParams } from "react-router-dom";
import SponsorCarousel from "../components/SponsorCarousel";

const News = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const { newsId } = useParams();

  useEffect(() => {
    setIsLoadingPage(true);
    if (newsId) {
      const request = async () => {
        const response = await client.get(`/news/${newsId}`);
        setNewsItems([response.data]);
      };
      request().then(() => setIsLoadingPage(false));
    } else {
      const request = async () => {
        const response = await client.get(`/news?page=${currentPage}`);
        setNewsItems(response.data.newsItems);
        setCurrentPage(response.data.currentPage);
        setTotalPages(
          Array.from(
            { length: response.data.totalPages },
            (_, index) => index + 1
          )
        );
      };
      request().then(() => setIsLoadingPage(false));
    }
  }, [currentPage, newsId]);

  console.log(newsItems);

  return (
    <Layout>
      <Flex
        alignItems={["center", "flex-start"]}
        justifyContent={"center"}
        flexDir={["column", "row"]}
        gap={5}
      >
        <Box
          w={["100%", "80%", "80%", "70%"]}
          p={[2, 6]}
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Skeleton isLoaded={!isLoadingPage}>
            <Heading
              fontFamily={"Afacad"}
              variant={"outline"}
              colorScheme="green"
              textDecor={"underline"}
              textDecorationColor={"green"}
            >
              Notícias
            </Heading>
            <Box
              display={["block", "none"]}
              w={["100%", "20%"]}
              alignSelf={"flex-start"}
            >
              <SponsorCarousel type="Principal" />
            </Box>
            <Flex
              alignItems={"center"}
              flexDir={"column"}
              maxW={"100%"}
              p={2}
              mt={8}
            >
              {newsItems[0] &&
                newsItems.map((news) => (
                  <Flex key={news.newsId} w={"100%"} flexDir={"column"}>
                    <hr />
                    <Text
                      fontSize={"1.8rem"}
                      fontFamily={"Afacad"}
                      fontWeight={700}
                    >
                      <Link to={`./${news.newsId}`}>{news.title}</Link>
                    </Text>
                    <Text fontSize={"0.8rem"} fontStyle={"italic"}>
                      {news.authorName} -{" "}
                      {format(new Date(news.createdAt), "dd/MM/yyyy")}
                    </Text>

                    <Text
                      m={3}
                      dangerouslySetInnerHTML={{ __html: news.text }}
                      fontFamily={"Afacad"}
                      textAlign={"justify"}
                    />
                    <Flex gap={5} wrap={"wrap"} py={5}>
                      {news.images.map((img) => (
                        <Image
                          borderRadius={8}
                          maxH={"400px"}
                          objectFit={"cover"}
                          src={`${process.env.REACT_APP_S3_BASEURL}/news_images/${img}`}
                        />
                      ))}
                    </Flex>
                  </Flex>
                ))}

              <Flex gap={2} mt={5} alignItems={"center"}>
                {totalPages[0] &&
                  totalPages.map((page) => {
                    if (page === currentPage) {
                      return <Text mx={2}>{page}</Text>;
                    } else {
                      return (
                        <Button
                          key={page}
                          colorScheme="linkedin"
                          size="sm"
                          variant={page === currentPage ? "outline" : "solid"}
                          disabled={page === currentPage ? "true" : "false"}
                          onClick={() => setCurrentPage(page)}
                        >
                          {page}
                        </Button>
                      );
                    }
                  })}
              </Flex>
            </Flex>
          </Skeleton>
        </Box>
        <Flex
          flexDir={"column"}
          w={["100%", "20%"]}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          <Box
            w={"100%"}
            display={["none", "block"]}
            justifySelf={"flex-start"}
          >
            <SponsorCarousel type="Principal" />
          </Box>
          <Box w={"100%"} justifySelf={"flex-start"}>
            <SponsorCarousel type="Secundário" />
          </Box>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default News;
