import { Box, Flex } from "@chakra-ui/react";
import Layout from "../components/Layout";
import LastNews from "../components/LastNews";
import About from "../components/About";
import Help from "../components/Help";
import Contacts from "../components/Contacts";

const Home = () => {
  return (
    <Layout>
      <Flex justifyContent={"center"}>
        <Box
          w={["100%", "90%", "90%", "90%"]}
          boxShadow="lg"
          bg="white"
          mt={[8]}
        >
          <Flex flexDir={"column"} gap={[5, 5]}>
            <LastNews />
            <About />
            <Help />
            <Contacts />
          </Flex>
        </Box>
        <hr />
      </Flex>
    </Layout>
  );
};

export default Home;
